import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import video from './video.mp4'
import bg from './bg.png';
import {FaPlay} from "react-icons/fa";

const Player = ({ currentVideoURL, playStarted }) => {

  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showSettings, setShowSettings] = useState(false);
  const playerRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedQuality, setSelectedQuality] = useState('auto');
  const [selectedSpeed, setSelectedSpeed] = useState(1);

  const handlePlayPause = () => {
    if (currentVideoURL) {
      setPlaying(!playing);
    }
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleSeekChange = (e) => {
    const fraction = parseFloat(e.target.value);
    setProgress(fraction);
    playerRef.current.seekTo(fraction, 'seconds');
  };
  const handleFullScreen = () => {
    const player = playerRef.current.getInternalPlayer();
    if (player) {
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.mozRequestFullScreen) {
        player.mozRequestFullScreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullscreen();
      } else if (player.msRequestFullscreen) {
        player.msRequestFullscreen();
      }
    }
  };

  const handleSettingsToggle = () => {
    setShowSettings(!showSettings);
  };

  const handleQualityChange = (e) => {
    setSelectedQuality(e.target.value);
  };

  const handleSpeedChange = (e) => {
    setSelectedSpeed(parseFloat(e.target.value));
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = (progressData) => {
    setProgress(progressData.playedSeconds);
    setCurrentTime(progressData.playedSeconds);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  function toggleMute() {
    if (volume === 1) {
      setVolume(0)
    } else {
      setVolume(1)
    }
  }

  useEffect(() => {
    if (playStarted) {
      setPlaying(true)
    }
  },[])

  return (
    <div>
      <Wrapper>
        <div className="player position-relative" style={{ minHeight: '100%', minWidth: '100%'}}>
          <ReactPlayer
            ref={playerRef}
            url={currentVideoURL}
            playing={playing}
            volume={volume}
            controls={false}
            width="100%"
            height="100%"
            config={{ file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }}}
            onPlay={() => setPlaying(true)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            // style={{ objectFit: 'contain', position: "absolute"}}
          />
          { !playing ? (
              <>
                <div className="position-absolute play-button" style={{
                  height: '100%',
                  width: '80px',
                  alignContent: 'center',
                  alignSelf: 'center',
                  color: "white"
                }}>
                  <button
                      type="button"
                      className="btn"
                      onClick={handlePlayPause}
                  >
                    <FaPlay style={{color: "white", height: '40px', width: '40px'}}/>
                  </button>
                </div>
              </>
          ) : null}
          {playing ? (
              <>
                <div className=" position-absolute play-button">
                  <div className="action_button">
                    <div className="content w-full">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {/* <div className="live_button col-2">
                    <button type="button"> <span></span> Live</button>
                  </div> */}

                        <div className="duration  d-flex justify-content-between align-items-center col-6 px-2">
                          <button type='button' onClick={handlePlayPause}>
                            <img src={playing ? '/img/icon/pause.svg' : '/img/icon/next.svg'} alt="next"/>
                          </button>
                          <p className='start_time'>{formatTime(currentTime)}</p>
                          <input
                              type="range"
                              min={0}
                              value={progress}
                              max={playerRef.current ? playerRef.current.getDuration() : 0}
                              step={1}
                              className='mx-2'
                              onChange={handleSeekChange}
                          />
                          <p className='end_time'>{formatTime(duration)}</p>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">

                          <button type='button' className=' position-relative' onClick={() => toggleMute()}>
                            <img src="/img/icon/audio.svg" alt="audio"/>
                          </button>
                          <button type='button'>
                            <img src="/img/icon/setting.svg" alt="setting"/>
                          </button>
                          <button type='button' onClick={handleFullScreen}>
                            <img src="/img/icon/fullscreen.svg" alt="fullscreen"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          ) : null}
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`

  .play-button {
    left: 0;
    right: 0;
    bottom: 20px;
    width: 90%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .toggle-action-button {
    left: 0;
    right: 0;
    bottom: 85px;
    width: 90%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .action_button {
    position: relative;
    height: 60px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;

    &::before {
      content: "";
      position: absolute;
      background: inherit;
      background-color: rgba(173, 137, 25, 0.29);
      filter: blur(10px); /* Adjust the blur radius as needed */
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .audio-volume {
      transform: rotate(270deg);
    }

    input[type="range"] {
      /* removing default appearance */
      -webkit-appearance: none;
      appearance: none;
      /* creating a custom design */
      width: 100%;
      cursor: pointer;
      outline: none;
      /*  slider progress trick  */
      overflow: hidden;
      border-radius: 16px;
    }

    /* Track: webkit browsers */

    input[type="range"]::-webkit-slider-runnable-track {
      height: 15px;
      background: #bea591;
      border-radius: 16px;
    }

    /* Track: Mozilla Firefox */

    input[type="range"]::-moz-range-track {
      height: 15px;
      background: #ccc;
      border-radius: 16px;
    }

    /* Thumb: webkit */

    input[type="range"]::-webkit-slider-thumb {
      /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #efb500;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #efb500;
  }


  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #efb500;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #efb500;
  }
        .content{
            width: 100%;
            position:relative;
            z-index: 3;
            .live_button{
                button{
                    background-color:rgba(255, 193, 0, 1);
                    padding: 6px 14px;
                    border-radius: 10px;
                    border: 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    color: #fff;
                    letter-spacing: 0em;
                    display: flex;
                    align-items: center;
                    justify-content:start;
                    span{
                        height: 10px;
                        width: 10px;
                        background-color:#fff;
                        border-radius: 25px;
                        display: block;
                        margin-right:5px;
                    }
                }
            }
            button{
                border: 0;
                background-color:transparent;
                padding: 0px 5px;
                img{
                 width:24px;
                 height:24px;
                }
            }
            .duration{
                p{
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    video{
      border-radius: 32px;
    }
`
export default Player;
