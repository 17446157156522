import {Box, Button, HStack, Image, Stack, Text, VStack} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const Subscriptions = ({ profile }) => {

    const packageData= [
        {
            bg:"/img/setting/sub1.png",
            name:"Principal Academy",
            price:"$99.99 / Year",
            url:""
        },
        {
            bg:"/img/setting/sub2.png",
            name:"Principal Academy",
            price:"$99.99 / Year",
            url:"",
            logo:"img/setting/logo1.png",
        },
        {
            bg:"/img/setting/sub3.png",
            name:"Principal Academy",
            price:"$99.99 / Year",
            url:"",
            logo:"img/setting/logo1.png",
        }
    ]
    const courseData =[
        {
            pic:"img/setting/course1.png",
            name:"Beginner’s Guide to  becoming a professional ",
            details:"The step-by-step plan to starting & scaling your own online business to $10,000/month through the most ",
            time:"Durations 03 h",
            timeIcon:"img/setting/clock.svg",
            book:"12 lessons",
            bookIcon:"img/setting/book.svg",
        }
    ]
    return(
        <div>
            <Wrapper className="my-subscriptions">
                <VStack w="50%" alignItems="flex-start" py="40px">
                    <Text className="title">Your Subscriptions</Text>
                    <Text className="details">These are your current subscriptions. They will be billed on the same cycle.
                        You can update any subscription at any time.</Text>
                </VStack>
                {
                    packageData.map((items,id)=>{
                        return(
                            <HStack w="100%" className="package-academy" p="20px" mb="20px" borderRadius="25px" backgroundImage={`url(${items.bg})`} key={id}>
                                <HStack w="50%" alignItems="center" justifyContent="flex-start">
                                    {
                                        items.logo ?
                                            <Box pr="20px">
                                                <Image src={items.logo} alt="logo1"/>
                                            </Box> : ''
                                    }
                                    <Box textAlign="left" >
                                        <HStack >
                                            <Text className="title">{items.name}</Text>
                                            <Text className="pro">PRO</Text>
                                        </HStack>
                                        <Text className="price" textAlign="left">{items.price}</Text>
                                    </Box>
                                </HStack>
                                <Stack w="50%" justifyContent="center" alignItems="center">
                                    <Button className="btn edit-subscription">Edit Subscription</Button>
                                </Stack>
                            </HStack>
                        )
                    })
                }


                <VStack className="your-course  align-items-start" w="100%" justifyContent="flex-start" items>
                    <Text className="title py-4">Your courses</Text>
                    <Box className="row" w="100%">
                        {
                            courseData.map((items,id)=>{
                                return(
                                    <Box className="col-md-4" key={id}>
                                        <Box className="course-items position-relative ">
                                            <Image src={items.pic} alt="course" className="top-image"/>
                                            <Box className="body-content p-4">
                                                <Text className="course-title pb-2">{items.name}</Text>
                                                <Text className="course-details pb-2" wordBreak="break-all">
                                                    {
                                                        items.details
                                                    } </Text>
                                                <HStack className="course-time">
                                                    <HStack>
                                                        <Image src={items.bookIcon} alt=""/>
                                                        <Text>{items.book} </Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Image src={items.timeIcon}  alt=""/>
                                                        <Text>{items.time} </Text>
                                                    </HStack>
                                                </HStack>
                                            </Box>
                                            <Box className="logo"  position="absolute" top="10px" left="10px" >
                                                <Image src="img/setting/parse.png" alt="parse"/>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </VStack>
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
.profile-details-area{
    background: linear-gradient(
    180.2deg,
    rgba(55, 43, 4, 0.4) -38.07%,
    rgba(255, 170, 0, 0.4) 88.45%
  );
  border: 1px solid rgba(255, 193, 0, 1);
  border-radius:16px;
}
.my-subscriptions{
    .title{
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
    }
    .package-academy{
        height: 111px;
        background-repeat: no-repeat;
        background-size: cover;
        .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(0, 0, 0, 1);
        }
        .pro{
            padding: 5px 10px;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
            border-radius: 5px;
        }
        .price{
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(0, 0, 0, 1);
        }
        .edit-subscription{
           width:160px;
           height: 45px;
           border-radius: 5px;
           display: flex;
           justify-content: center;
           align-items: center;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
        }
    }
}
.your-course{
    .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
        }
    .course-items{

        .top-image{
            height: 213px;
            width: 100%;
            object-fit: cover;
        }
        border-radius: 25px;
        overflow: hidden;
            .body-content{
                background-color: rgba(51, 39, 12, 1);
                .course-title{
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
                .course-details{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(225, 225, 225, 1);
                }

                .course-time{
                    img{
                        width: 21px;
                        height: 21px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(184, 184, 184, 1);
                    }
                }
            }
    }
}
    
.divider{
    background: rgba(255, 180, 89, 1);
    height: 2px;
    width: 100%;
    margin:15px 0;
}
.payment-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05em;
    text-align: left;
}
.payment-method{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}
.payment-details{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 193, 0, 1);
}
.payment-btn{
    width:187px;
    height:45px;
    border-radius: 5px;
    background-color: rgba(166, 126, 0, 1);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
table{
    background-color: transparent;
    border:0;
    tr{
        background-color: transparent;
        border:0;

    }
    tbody{
        th{
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
    td{
        background-color: transparent;
        border:0;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    th{
        background-color: transparent;
        border:0;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .complete-btn{
        background-color: rgba(18, 132, 77, 0.4);
        color: rgba(123, 250, 188, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
    .pending-btn{
        background: rgba(255, 193, 0, 0.3);
        color: rgba(255, 193, 0, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }    .failed-btn{
        background: rgba(255, 93, 66, 0.42);
        color: rgba(255, 164, 149, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
}

  .top-bar {
    height: 83px;
    border-radius: 15px;
    background: linear-gradient(
      143.07deg,
      rgba(55, 43, 4, 0.4) -14.08%,
      rgba(255, 170, 0, 0.4) 34.01%
    );
    .btn {
      width: 160px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      height: 45px;
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 193, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background-color: rgba(255, 193, 0, 1);
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .profile-title {
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    .profile-add {
      background-image: url("/img/setting/circle-dil.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 97.7px;
      width: 97.7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 65px;
        height: 65px;
      }
      .overly {
        position: absolute;
        bottom: 10px;
        left: 8px;
        .btn {
          background-color: rgba(255, 193, 0, 1);
          width: 26px;
          height: 26px;
          border-radius: 25px;
          padding: 0;
          svg {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
  .profile-information{
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
        padding-bottom: 10px;
    }
    input{
        background: #503c11;
        border: 1px solid #FFC100;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(166, 126, 0, 1);
        &::placeholder{
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
        }
    }
    #Phone{
        padding-left:65px;
    }
    button{
        &:focus-within{
            border: 0;
        }
        &:focus-within{
            border: 0;
        }
    }
    .react-datepicker-wrapper{
        width: 100%;

    }
    .calender{
            left: 0;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content:center;
            svg{
                color: rgba(255, 193, 0, 1);
                font-size: 18px;
            }
    }
    .react-datepicker__input-container{
        input{
            width:100% !important;
            padding-left:45px;
            border-radius: 10px;
        }
    }
    .password-area{
        svg{
            color: rgba(254, 196, 79, 1);
            font-size:18px;
        }
    }
    .save-change{
        .btn{
            background-color: rgba(255, 193, 0, 1);
            border-radius: 6px;
            height: 44px;
            width:171px;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
  }
  .twoFactor-information{
    background-color: rgba(134, 91, 7, 1);
    border-radius:11px;
    .form-check-input:checked{
        background-color:rgba(255, 193, 0, 1);
        border:rgba(255, 193, 0, 1);
    }
    .form-switch .form-check-input{
        height: 20px;
        width: 54px;
    }
    .form-group{
        label{
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
            padding-bottom: 10px;
        }
        input{
            background: #503c11;
            border: 1px solid #FFC100;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
            &::placeholder{
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(166, 126, 0, 1);
            }
        }
    }
    .continue-btn{
        background-color: rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
    .canceled-btn{
        border: 1px solid rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
  }
  .invoice-area{
    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
  }
  .modal-area{
    .upload-title{
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
    }
    .upload-btn-close{
        font-size:32px;
        padding: 0;
        svg{
            color: rgba(255, 193, 0, 1);
            font-size: 34px !important;
        }
    }
    .browse-txt{
        font-family: Fira Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 253, 253, 1);
        padding-bottom:30px;
        padding-top:10px;
    }
    .fileSize-txt{
        padding-bottom:7px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .fileFormat-txt{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .save-btn{
        background: linear-gradient(0deg, #FFC100, #FFC100);
        width: 177px;
        height:53px;
        border-radius: 5px;
        border: 1px solid rgba(255, 193, 0, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color:rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
`;

export default Subscriptions