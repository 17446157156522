// useRouter.js

import { useNavigate, useLocation, useParams } from 'react-router-dom';

const useRouter = () => {
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    const navigateBack = () => {
        navigate(-1)
    };

    const refresh = () => {
        navigate(0)
    }

    return {
        push: navigateTo,
        back: navigateBack,
        refresh: refresh
    };
};

export default useRouter;
