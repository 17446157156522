import { Box, Button, HStack, IconButton, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaUser } from 'react-icons/fa6';
import { RiPushpinLine } from "react-icons/ri";
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const EducatorsList = (props) => {

	const navigate = useNavigate();

	const { educators } = props;

	function handleEducatorSelected(educator) {
		navigate(`/education/${educator._id}`)
	}

	function handleEducatorProfileSelected(educator) {
		navigate(`/profile/${educator._id}`)
	}

	function handleSubscribe(educator) {
		console.log(educator);
	}

	return (
		<Wrapper className='py-5'>
			 <div className="educators-area educator-slider">
			 	<div  className='row p-5 '  w="100%" >
				 {educators && educators.map((items, id) => {
					  return (
						<Box className="col-md-3 overflow-hidden p-3" key={id}>
						  <Box className="course-items position-relative w-100 overflow-hidden" style={{ borderRadius: '16px' }}>
							<Image onClick={() => handleEducatorSelected(items.educator)} src={items.educator.banner} alt="course" className="top-image" style={{ objectFit: 'cover', borderRadius: '0px'}} h={'250px'} minH={'250px'} w="100%" />
							<Box onClick={() => handleEducatorSelected(items.educator)} className="body-content p-4 overflow-hidden" >
							  <Text className="course-title pb-2">{items.educator.name}</Text>
							  <Text className="course-details pb-2" wordBreak="break-all">
								{items.educator.bio} FFF
							  </Text>
							  <HStack className="course-time py-3">
								<Button className="btn profile-btn" onClick={() => handleEducatorProfileSelected(items.educator)}>
								  <Image src="/img/userIcon.png"  alt="" mx="2"/>Profile
								</Button>
								  <Button onClick={() => handleSubscribe(items.educator)} className="btn subscribe-btn">{items.subscribed ? "Unsubscribe" : "Subscribe"}</Button>
							  </HStack>
							</Box>
							<Box className="logo" position="absolute" top="10px" left="10px">
							  <Image w={'100px'} src="img/course-logo-2.png" alt="parse" />
							</Box>
						  </Box>
						</Box>
					  );
					})}
				</div>
				<div className="pagination w-100 px-5 pb-5">
					<div className="d-flex justify-content-between align-items-center w-100">

						<div className="">
						<nav aria-label="Page navigation example">
							<ul class="pagination">
								<li class="page-item"><a class="page-link" href="#">Prev</a></li>
								<li class="page-item"><a class="page-link" href="#">1</a></li>
								<li class="page-item"><a class="page-link" href="#">2</a></li>
								<li class="page-item"><a class="page-link" href="#">3</a></li>
								<li class="page-item"><a class="page-link" href="#">Next</a></li>
							</ul>
							</nav>
						</div>
					</div>
				</div>
			 </div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	.educators-area{
		background: linear-gradient(180.2deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
		border-radius: 30px;
	}
.items{
	background: linear-gradient(180.2deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
	border-radius:14px;
	padding:15px ;
	h1{
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: left;
		color: rgba(255, 255, 255, 1);
	}
	.top-bar{
		button{
			background-color: rgba(255, 193, 0, 1);
			color: rgba(255, 255, 255, 1);
			width: 68px;
			height: 34px;
			border: 1px solid rgba(255, 193, 0, 1)
			svg{
				margin-right:5px;
			}
		}
	}
	.details{
		p{
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			letter-spacing: 0em;
			text-align: left;
			color: rgba(255, 255, 255, 1);
		}
	}
	.btn-outline-primary{
		border-color: rgba(255, 193, 0, 1);
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: left;
		height: 40px;
		svg{
			margin-right:5px;
		}
		&:hover{
			background-color:rgba(255, 193, 0, 1);
		}
	}
	.btn-primary{
		background-color:rgba(255, 193, 0, 1);
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: left;
		border-color: rgba(255, 193, 0, 1);
		height: 40px;
		svg{
			margin-right:5px;
		}
		&:hover{
			border-color: rgba(255, 193, 0, 1);
		}
	}
}
.pagination{
	.btn-primary{
		background-color:rgba(255, 193, 0, 0.4);
		color: rgba(255, 193, 0, 1);
		border: 1px solid rgba(255, 193, 0, 1);
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0.04em;
		text-align: left;
		height: 40px;
	}
	.page-item{
		margin-right:5px;
		background-color: transparent;
		a{
			background-color: transparent;
			color: rgba(255, 193, 0, 1) ;
			border: 1px solid rgba(255, 193, 0, 1)
		}
	}
}
`
export default EducatorsList;