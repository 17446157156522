import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import SettingsSidebar from './components/SettingsSidebar';
import DashboardLayout from '../../components/DashboardLayout';
import { Box, HStack } from '@chakra-ui/react';
import ProfileDetails from './components/ProfileDetails';
import userProvider from "../../hooks/UserProvider";
import NetworkingAPI from "../../hooks/NetworkingAPI";
import ProfilePhotoModal from "./components/ProfilePhotoModal";
import ProfileCoverModal from "./components/ProfileCoverModal";
import {toast} from "react-toastify";
const Setting = ({mode,toggleMode}) => {

  const [profile, setProfile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showCoverModal, setCoverShowModal] = useState(false);
  const [uploadType, setUploadType] = useState();
  const [count, setCount] = useState(0); // State for triggering re-render

  async function initializePage() {
    const currentUser = await userProvider.getCurrentUser(false);

    if (currentUser) {
      try {
        const dataResponse = await NetworkingAPI.fetchData('auth/profile', "POST", { memberId: currentUser._id});
        console.log(dataResponse);
        if (dataResponse.data) {
          setProfile(dataResponse.data);
          // console.log(dataResponse.data)
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  function handleSuccessfulUpload(title) {
    if (!title) {
      return ''
    }
    toast.success(`Successfully uploaded ${title}`)
    return setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  function handleShowModal(type) {
    console.log(type);
    setUploadType(type)
    if (type === 'cover') {
      setCoverShowModal(true);
    }
    if (type === 'profile') {
      setShowModal(true);
    }
  }

  useEffect(() => {
    initializePage()
  },[]);


  useEffect(() => {
    setCount(prevCount => prevCount + 1);
  },[])

  return (
    <div>
      <Wrapper>
        <DashboardLayout title={"My Account"} mode={mode} toggleMode={toggleMode}>
          <HStack py="15px" flexDir="row" flexWrap="nowrap" alignItems="start">
            { profile && (
                <>
                  <Box w="496px" height="90vh">
                    <SettingsSidebar mode={mode} toggleMode={toggleMode} profile={profile} showModal={handleShowModal} toggleMode={toggleMode} />
                  </Box>
                  <Box w="100%" px="25px" overflowY="auto" height="89vh" className='profile-details'>
                    <ProfileDetails mode={mode} profile={profile} showModal={handleShowModal}/>
                  </Box>
                </>
            )}
          </HStack>
          <ProfilePhotoModal type={uploadType} show={showModal} onClose={() => setShowModal(false)} onSuccess={() => handleSuccessfulUpload('profile image')}/>
          <ProfileCoverModal type={uploadType} show={showCoverModal} onClose={() => setCoverShowModal(false)} onSuccess={() => handleSuccessfulUpload('cover image')}/>
        </DashboardLayout>
      </Wrapper>
    </div>
  );
};
const Wrapper =styled.div`
  .profile-details{
    &::-webkit-scrollbar{
      display: none;
    }
  }
`
export default Setting;















































// import React, { useState, useEffect, useRef } from "react";
// import { AddIcon, CameraIcon } from "../../components/Icon";
// import DashboardLayout from "../../components/DashboardLayout";
// import InputLayout from "./components/InputLayout";
// import { baseURL, corsEverywhere } from "../../hooks/paths";
// import { storageRef, firestoreDatabaseRef } from "../../hooks/firebase";
// import { v1 as uuidv1 } from 'uuid';

// export default function Setting() {
//   const [displayName, setDisplayName] = useState("");
//   const [userName, setUserName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phone, setPhone] = useState("");
//   const [profilePhoto, setProfilePhoto] = useState("");
//   const [bannerPhoto, setBannerPhoto] = useState("");
//   const [password, setPassword] = useState("password");

//   // Billing

//   const [addressOne, setAddressOne] = useState("");
//   const [addressTwo, setAddressTwo] = useState("");
//   const [city, setCity] = useState("");
//   const [state, setState] = useState("");
//   const [zipcode, setZipcode] = useState("");
//   const [country, setCountry] = useState("");

//   // Edit
//   const [isDisplayNameEdit, setIsDisplayNameEdit] = useState(false);
//   const [isUserNameEdit, setIsUserNameEdit] = useState(false);
//   const [isEmailEdit, setIsEmailEdit] = useState(false);
//   const [isPhoneEdit, setIsPhoneEdit] = useState(false);
//   const [isPasswordEdit, setIsPasswordEdit] = useState(false);

//   // Billing - Edit

//   const [isAddressOneEdit, setIsAddressOneEdit] = useState(false);
//   const [isAddressTwoEdit, setIsAdressTwoEdit] = useState(false);
//   const [isCityEdit, setIsCityEdit] = useState(false);
//   const [isStateEdit, setIsStateEdit] = useState(false);
//   const [isZipcodeEdit, setIsZipcodeEdit] = useState(false);

//   //

//   const profileInputFile = useRef(null);
//   const bannerInputFile = useRef(null);

//   //

//   const [twoStep, setTwoStep] = useState(true);

//   const changeBannerImageTriggered = () => {
//     // console.log('Change banner image triggered');
//     bannerInputFile.current.click();
//   };

//   const changeProfileImageTriggered = () => {
//     // console.log('Change profile image triggered');
//     profileInputFile.current.click();
//   };

//   const fetchUserProfile = () => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user) {
//       const memberId = user.id;
//       const url = corsEverywhere + baseURL + 'profile/profile';
//       try {
//         fetch(url, {
//           method: 'POST',
//           headers: {
//             'origin': '*',
//             'x-requested-with': '*',
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({memberId: memberId})
//         }).then(async (response) => {
//           if (response.ok) {
//             const responseJSON = await response.json();
//             const fetchedData = responseJSON.data;
//             if (fetchedData != undefined && fetchedData != null) {
//               setDisplayName(fetchedData.name);
//               setUserName(fetchedData.username);
//               setEmail(fetchedData.email);
//               setPhone(fetchedData.mobile);
//               setPassword(fetchedData.password);
//               setProfilePhoto(fetchedData.photo);
//               setBannerPhoto(fetchedData.banner);
//               // BILLING
//               setAddressOne(fetchedData.address_1 ?? '');
//               setAddressTwo(fetchedData.address_2 ?? '');
//               setCity(fetchedData.city ?? '');
//               setState(fetchedData.state ?? '');
//               setZipcode(fetchedData.zipcode ?? '');
//               setCountry(fetchedData.country ?? '');
//             } else {
//               console.log('Failed to fetch profile');
//             };
//           } else {
//             console.log('Failed to fetch data 2');
//           }
//         })
//       } catch(error) {
//         console.log('Failed to fetch data');
//         console.log(error);
//       }
//     }
//   };

//   const saveChanges = (e, isEditing) => {
//     const memberId = localStorage.getItem('memberId');

//     var poststring = {};

//     if (e === 'username') {
//       setIsUserNameEdit(isEditing);
//       poststring = {field: 'username', field: userName, memberId: memberId};
//     } else if (e === 'name') {
//       setIsDisplayNameEdit(isEditing);
//       poststring = {field: 'name', value: displayName, memberId: memberId};
//     } else if (e === 'email') {
//       setIsEmailEdit(isEditing);
//       poststring = {field: 'email', value: email, memberId: memberId};
//     } else if (e === 'password') {
//       setIsPasswordEdit(isEditing);
//       poststring = {field: 'password', value: password, memberId: memberId};
//     } else if (e === 'mobile') {
//       setIsPhoneEdit(isEditing);
//       poststring = {field: 'mobile', value: phone, memberId: memberId};

//       // BILLING INFORMATION
//     } else if (e === 'address_1') {
//       setIsAddressOneEdit(isEditing);
//       poststring = {field: 'address_1', value: phone, memberId: memberId};
//     } else if (e === 'address_2') {
//       setIsAdressTwoEdit(isEditing);
//       poststring = {field: 'address_2', value: phone, memberId: memberId};
//     } else if (e === 'city') {
//       setIsCityEdit(isEditing);
//       poststring = {field: 'city', value: phone, memberId: memberId};
//     } else if (e === 'state') {
//       setIsStateEdit(isEditing);
//       poststring = {field: 'state', value: phone, memberId: memberId};
//     } else if (e === 'zipcode') {
//       setIsZipcodeEdit(isEditing);
//       poststring = {field: 'zipcode', value: phone, memberId: memberId};
//     } else {

//     }

//     if (memberId && isEditing === false) {
//       const url = corsEverywhere + baseURL + 'profile/update';
//       try {
//         fetch(url, {
//           method: 'POST',
//           headers: {
//             'origin': '*',
//             'x-requested-with': '*',
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(poststring)
//         }).then(async (response) => {
//           if (response.ok) {
//             console.log('Successfully stored user information');
//           } else {
//             console.log('Failed to store user information');
//           }
//         })
//       } catch(error) {
//           console.log(error);
//       }
//     }
//   };

//   const handleSelectedBannerImage = (e) => {
//     const file = e.target.files[0];
//     const mimeType = file.type;
//     const user = JSON.parse(localStorage.getItem('user'));
//     const imageExtension =  mimeType.split('/')[1];
//     const uid = uuidv1();
//     const uploadTask = storageRef.ref().child('profile_banners').child(user.id ?? 'default').child(`${uid}.${imageExtension}`).put(file);

//     uploadTask.then((snapshot) => {
//       const fullPath = snapshot.metadata.fullPath;
//       storageRef.ref().child(fullPath).getDownloadURL().then((downloadURL) => {
//         const poststring = {banner: downloadURL};
//         setBannerPhoto(downloadURL);
//         firestoreDatabaseRef.collection('members').doc(user.id ?? '').set(poststring, {merge: true});
//       })
//     }).catch((error) => {
//       console.log(error);
//     });
//   };

//   const handleSelectedProfileImage = (e) => {
//     const file = e.target.files[0];
//     const mimeType = file.type;
//     const user = JSON.parse(localStorage.getItem('user'));
//     const imageExtension =  mimeType.split('/')[1];
//     const uid = uuidv1();
//     const uploadTask = storageRef.ref().child('profile_photos').child(user.id ?? 'default').child(`${uid}.${imageExtension}`).put(file);

//     uploadTask.then((snapshot) => {
//       const fullPath = snapshot.metadata.fullPath;
//       storageRef.ref().child(fullPath).getDownloadURL().then((downloadURL) => {
//         const poststring = {photo: downloadURL};
//         setProfilePhoto(downloadURL);
//         firestoreDatabaseRef.collection('members').doc(user.id ?? '').set(poststring, {merge: true});
//       })
//     }).catch((error) => {
//       console.log(error);
//     });
//   };

//   useEffect(() => {
//     fetchUserProfile();
//   },[]);

//   return (
//     <>
//     <input type="file" id="bannerInputFile" ref={bannerInputFile} style={{display:'none'}} onChange={(e) => handleSelectedBannerImage(e)}/>
//     <input type="file" id="profileInputFile" ref={profileInputFile} style={{display:'none'}} onChange={(e) => handleSelectedProfileImage(e)}/>
//       <DashboardLayout title="My Account">
//         <div className="setting-wrapper">
//           <div className="box-groups">
//             <div className="box-item ">
//               <div className="img-contents">
//                 <img className="cover-photo" src={bannerPhoto && bannerPhoto !== '' ? bannerPhoto : "/img/placeholderImage.png"} alt="" style={{objectFit: 'cover'}}/>
//                 {/* src="/img/setting/1.png" */}
//                 <button className="change-cover-btn" onClick={() => changeBannerImageTriggered()} style={{marginRight: '5px'}} >
//                   <CameraIcon color="#FFC100" />
//                   Change Cover
//                 </button>
//                 <div className="profile-img-content">
//                   <div className="dil-circle">
//                     <img
//                       className="img-fluid"
//                       src="/img/setting/circle-dil.png"
//                       alt=""
//                     />
//                     <img
//                       src={profilePhoto && profilePhoto !== '' ? profilePhoto : "/img/blankUser.png"}
//                       alt=""
//                       className="profile-img"
//                     />

//                     <button className="change-profile-photo-btn" onClick={() => changeProfileImageTriggered()}>
//                       <CameraIcon color="white" />
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <h4 className="profile-name">{displayName}</h4>
//               <h5 className="profile-intro">Principles Academy Member</h5>

//               <div className="number-contents">
//                 <div className="number-item">
//                   <h6>0</h6> <span>Following</span>
//                 </div>
//                 <div className="number-item">
//                   <h6>0</h6> <span>Followers</span>
//                 </div>
//                 <div className="number-item">
//                   <h6>$0.00</h6> <span> BALANCE</span>
//                 </div>
//               </div>

//               <div className="description">
//                 <h3 className="description-title">Biography:</h3>

//                 <p>
//                   Just your average developer!
//                 </p>
//               </div>

//               {/* <div className="account-removal">
//                 <h3>Account Management</h3>
//                 <p>
//                   Disabling your account means you can recover it at any time
//                   after taking this action.
//                 </p>
//                 <div className="btn-groups">
//                   <button>Disable Account</button>
//                   <button>Delete Account</button>
//                 </div>
//               </div> */}
//             </div>
//             <div className="box-item right-side">
//               <div className="top d-flex align-items-center justify-content-between">
//                 <h2>Account Information</h2>
//                 <div className="profile">
//                   <div className="right-side-profile-circle">
//                     <img
//                       className="img-fluid"
//                       src="/img/setting/circle-dil.png"
//                       alt=""
//                     />
//                     <img
//                       src={profilePhoto && profilePhoto !== '' ? profilePhoto : "/img/blankUser.png"}
//                       alt=""
//                       className="profile-img"
//                     />

//                     <button className="add-profile-btn">
//                       <AddIcon />
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div>
//                 <div className="input-groups">
//                   <InputLayout
//                     label="Full Name"
//                     value={displayName}
//                     type="text"
//                     setInput={setDisplayName}
//                     setEdit={(e) => saveChanges('name', e)}
//                     edit={isDisplayNameEdit}
//                   />
//                   {/* <InputLayout
//                     label="Username"
//                     value={userName}
//                     type="text"
//                     setInput={setUserName}
//                     setEdit={(e) => saveChanges('username', e)}
//                     edit={isUserNameEdit}
//                   /> */}
//                   <InputLayout
//                     label="Email"
//                     value={email}
//                     type="email"
//                     setInput={setEmail}
//                     setEdit={(e) => saveChanges('email', e)}
//                     edit={isEmailEdit}
//                   />
//                   <InputLayout
//                     label="Phone"
//                     value={phone}
//                     type="text"
//                     setInput={setPhone}
//                     setEdit={(e) => saveChanges('mobile', e)}
//                     edit={isPhoneEdit}
//                   />
//                   <InputLayout
//                     label="Password"
//                     value={password}
//                     type="password"
//                     setInput={setPassword}
//                     setEdit={(e) => saveChanges('password', e)}
//                     edit={isPasswordEdit}
//                     setDisabled={true}
//                   />
//                 </div>
//                 <div className="top d-flex align-items-center justify-content-between">
//                   <h2 style={{marginTop: '40px'}}>Billing Information</h2>
//                 </div>
//                 <div className="input-groups">
//                   <InputLayout
//                     label="Address 1"
//                     value={addressOne}
//                     type="text"
//                     setInput={setAddressOne}
//                     setEdit={(e) => saveChanges('address_1', e)}
//                     edit={isDisplayNameEdit}
//                   />
//                   <InputLayout
//                     label="Address 2 (optional)"
//                     value={addressTwo}
//                     type="text"
//                     setInput={setAddressTwo}
//                     setEdit={(e) => saveChanges('address_2', e)}
//                     edit={isUserNameEdit}
//                   />
//                   <InputLayout
//                     label="City"
//                     value={city}
//                     type="text"
//                     setInput={setCity}
//                     setEdit={(e) => saveChanges('city', e)}
//                     edit={isEmailEdit}
//                   />
//                   <InputLayout
//                     label="State"
//                     value={state}
//                     type="text"
//                     setInput={setState}
//                     setEdit={(e) => saveChanges('state', e)}
//                     edit={isPhoneEdit}
//                   />
//                   <InputLayout
//                     label="Zipcode"
//                     value={zipcode}
//                     type="text"
//                     setInput={setZipcode}
//                     setEdit={(e) => saveChanges('zipcode', e)}
//                     edit={isZipcodeEdit}
//                   />
//                 </div>
//               </div>

//               {/* Two-step authentication */}
//               {/* <div className="two-step-verification">
//                 <div className="d-flex align-items-center justify-content-between">
//                   <h3>Two-step authentication</h3>
//                   <div
//                     onClick={() => setTwoStep(!twoStep)}
//                     className={
//                       twoStep ? "toggole justify-content-end" : "toggole"
//                     }
//                     style={{
//                       backgroundColor: !twoStep ? "#543a04" : "#ffc100",
//                     }}
//                   >
//                     <div className="white-circle"></div>
//                   </div>
//                 </div>

//                 <p>To continue, please enter your password.</p>

//                 <div>
//                   <input type="email" placeholder="Email address" />
//                   <input type="password" placeholder="Password" />

//                   <div className="btn-groups">
//                     <button>Save Changes</button>
//                     <button>Cancel</button>
//                   </div>
//                 </div>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </DashboardLayout>
//     </>
//   );
// }
