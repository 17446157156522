import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


const AuthLayout = ({ title, subtitle, steps, children }) => {
	return (
		<Wrapper>
			<section
				className="auth-layout d-flex w-100"
				style={{
					background: `url('/img/auth-steps/auth-bg.png') no-repeat center center /  cover fixed`,
				}}
			>
				<div className="auth-left col-md-6">
					{/* <img
						src="/img/auth-steps/vector.png"
						className="main-img"
						alt=""
					/> */}
					<img
						src="img/light/light2.png"
						className="main-img"
						alt=""
					/>
					<Link to="/" className="logo dark-logo">
						<img src="/img/logo.png" alt="" />
					</Link>
					<Link to="/" className="logo light-logo">
						<img src="/img/lightLogo.png" alt="" />
					</Link>
					<div className="d-flex justify-content-between mx-auto step-items">
						{data.map((item, index) => (
							<div
								className={`step-item`}
								style={{ opacity: steps === index + 1 ? "1" : "0.4" }}
								key={index}
							>
								<img src={item.img} alt="" />
							</div>
						))}
					</div>
				</div>
				<div className=" col-md-6 d-flex justify-content-start align-items-center">
					<div className="auth-right">
						<div className="auth-content">
							{title && (
								<div className="auth-header">
									<h2 className="title mb-3">{title}</h2>
									{subtitle && <p className="subtitle">{subtitle}</p>}
								</div>
							)}
							{children}
						</div>
					</div>
				</div>
			</section>
		</Wrapper>
	);
};

const data = [
	{
		img: "/img/auth-steps/1.png",
	},
	{
		img: "/img/auth-steps/2.png",
	},
	{
		img: "/img/auth-steps/3.png",
	},
];

const Wrapper =styled.div`
height: 100vh;
display: flex;
align-items:center;
width: 100%;
`
export default AuthLayout;
