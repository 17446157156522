import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import AnswerFollowing from "./pages/auth/AnswerFollowing";
import AuthPlan from "./pages/auth/AuthPlan";
import AuthVerifyEmail from "./pages/auth/AuthVerifyEmail";
import Quiz from "./pages/auth/Quiz";
import Signup from "./pages/auth/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import Affiliate from "./pages/affiliate/Affiliate";
import Education from "./pages/education/Education";
import EducationExpand from "./pages/education-expand/EducationExpand";
import NewsFeed from "./pages/newsfeed/NewsFeed";
import Teacher from "./pages/teacher/Teacher";
import Chatrooms from "./pages/chatting/Chatting";
import Settings from "./pages/setting/Setting";
import EducatorProfile from "./pages/profile/Profile";
import ProtectedRoute from "./routes/ProtectedRoutes";

import { useEffect, useState } from "react";
import Login from "./pages/auth/Login";
import Course from "./pages/course/Course";
import CourseDetails from "./pages/course/CourseDetails";
import SelectedModule from "./pages/course/SelectedModule";
import AuthProvider from "./hooks/AuthProvider";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [mode, setMode] = useState('light');
	useEffect(() => {
		// const resetStatus = localStorage.getItem('reset') ?? false;
		// if (resetStatus === false) {
		// 	localStorage.removeItem('memberId');
		// 	localStorage.removeItem('user');
		// 	localStorage.setItem('reset', true);
		// } else {
		// 	const userData = localStorage.getItem('user');
		// 	if (userData) {
		// 		setIsAuthenticated(true);
		// 	} else {
		// 		setIsAuthenticated(false);
		// 	}
		// };
		const userData = localStorage.getItem('user');
		if (userData) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	});
	const toggleMode = () => {
		setMode(mode === 'light' ? 'dark' : 'light');
	  };
	return (
		<div className={mode}>
			<BrowserRouter>
				<AuthProvider>
					<ToastContainer />
					{/*<Routes>*/}
					{/*	<Route path="/"*/}
					{/*		   element={isAuthenticated ? <Navigate to="/dashboard"/> : <Navigate to="/login"/>}/>*/}
					{/*	<Route path="/dashboard"*/}
					{/*		   element={<Dashboard mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/affiliate"*/}
					{/*		   element={<Affiliate mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/educators"*/}
					{/*		   element={<Teacher mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/news" element={<NewsFeed mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/chatrooms"*/}
					{/*		   element={<Chatrooms mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/myaccount"*/}
					{/*		   element={<Settings mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/profile/:educatorId"*/}
					{/*		   element={<EducatorProfile mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/education"*/}
					{/*		   element={<Teacher mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/education/:educatorId/:courseId/modules"*/}
					{/*		   element={<SelectedModule mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/education/:educatorId"*/}
					{/*		   element={<Course mode={mode} toggleMode={toggleMode}/>}/>*/}
					{/*	<Route path="/education/:courseId/lesson/:lessonId"*/}
					{/*		   element={<CourseDetails mode={mode} toggleMode={toggleMode}/>}/>*/}

					{/*	<Route path="/login"*/}
					{/*		   element={<Login/>}/>*/}
					{/*	<Route path="/signup" element={<Signup/>}/>*/}
					{/*	<Route path="/signup/plan" element={<AuthPlan/>}/>*/}
					{/*	<Route path="/signup/questionnaire" element={<AnswerFollowing/>}/>*/}
					{/*	<Route path="/signup/questionnaire/quiz/:quizId" element={<Quiz/>}/>*/}
					{/*	<Route path="/signup/verify" element={<AuthVerifyEmail/>}/>*/}
					{/*</Routes>*/}
				</AuthProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
