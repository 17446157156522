import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB8BBjrX1OpoRVrUdGZvRZiXPmFTXv-rsk",
    authDomain: "principles-academy-77823.firebaseapp.com",
    projectId: "principles-academy-77823",
    storageBucket: "principles-academy-77823.appspot.com",
    messagingSenderId: "674535760604",
    appId: "1:674535760604:web:c12d4938f7afefdc3aca19",
    measurementId: "G-G3X362YF76"
};

export const firebaseApp = initializeApp(firebaseConfig);
const compatApp = firebase.initializeApp(firebaseConfig);
export const realtimeDatabaseRef = getDatabase(firebaseApp);
export const firestoreDatabaseRef = compatApp.firestore();
export const storageRef = compatApp.storage()
