import React, { useEffect, useRef, useState } from "react";
import { PiArrowElbowUpLeftDuotone } from "react-icons/pi";
import { HiDotsHorizontal } from "react-icons/hi";
import { FiSend } from "react-icons/fi";
import { realtimeDatabaseRef } from "../../../hooks/firebase";
import { onValue, onChildAdded, ref, set } from "firebase/database";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { baseURL, corsEverywhere } from "../../../hooks/paths";
import styled from "styled-components";
import { Box, Button, Flex, HStack, Image } from "@chakra-ui/react";
import UserProvider from "../../../hooks/UserProvider";
import NetworkingAPI from "../../../hooks/NetworkingAPI";

const LiveChat = ({ mode }) => {

    const params = useParams();

    const [users, setAllUsers] = useState();
    const [typedMessage, setTypedMessage] = useState('');
    const [chatroom, setChatroom] = useState();

    const memberId = localStorage.getItem('memberId');

    const fetchChatroom = async () => {
        const currentUser = await UserProvider.getCurrentUser();
        if (currentUser) {
            try {
                const dataResponse = await NetworkingAPI.fetchData(`education/comments/retrieve/${params.lessonId}`,"POST", undefined);
                if (dataResponse) {
                    const messages = dataResponse.result
                    setChatroom(messages)
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    const handleSubmit = async (e) => {
		e.preventDefault();
        const user = await UserProvider.getCurrentUser();
        if (user && typedMessage !== '') {
            const poststring = {
                sender: user._id ?? '',
                message: typedMessage
            };
            console.log(poststring)
            const currentUser = await UserProvider.getCurrentUser();
            if (currentUser) {
                try {
                    const dataResponse = await NetworkingAPI.fetchData(`education/comments/create/${params.lessonId}`,"POST", poststring);
                    if (dataResponse) {
                        await fetchChatroom();
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
        setTypedMessage('');
	};

    const handleMessageTyped = (e) => {
        const value = e.target.value;
        setTypedMessage(value);
    };

    const getDateFromTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        // Get month abbreviation
        const monthAbbreviation = date.toLocaleString('default', { month: 'short' });

        // Get day of the month and time in the desired format
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const timeFormat = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Construct the formatted timestamp string
        const formattedTimestamp = `${monthAbbreviation} ${day} ${formattedHours}:${formattedMinutes} ${timeFormat}`;
        return formattedTimestamp;
    };

    useEffect(() => {
        fetchChatroom();
        
    },[]);

    return(
        <Wrapper mode={mode} className="p-4 my-5">
            <div className="live-chat mt-3">
                    <div className="d-flex justify-content-between  align-items-center top-bar">
                        <h2>Comments</h2>
                        <Button  className="btn d-flex align-items-center filter-btn" >
                            <Image src="/img/icon/filter-square.svg" alt="filter" mr="5px"/>
                            Sort by
                        </Button>
                    </div>    
              </div>
              <div className="chat-area  mt-4">
                    <div class="mb-3 form-group position-relative">
                    <input onChange={handleMessageTyped} value={typedMessage} type="text" class="form-control" id="message" placeholder="Write your message here"/>
                         <div className="dot-menu">
                            <button type="button" className="btn "> <HiDotsHorizontal color={"#000000"} /></button>
                         </div>
                        <div className="send-btn2">
                            <button onClick={(e) => handleSubmit(e)} type="button" className="btn "> <FiSend /></button>
                        </div>

                    </div>
              </div>
            {chatroom && chatroom.map((item, index) => (
                <div key={index} className="list_of_comments w-100 py-3">
                    <Flex alignItems="flex-start" w="100%" className="comments_items">
                        <Box className="avatar">
                            <Image height={42} width={42} style={{ borderRadius: '21px'}} src={item.user.photo !== '' ? (item.user.photo ?? '/img/blankUser.png') : "/img/blankUser.png"} alt="avatar1"/>
                        </Box>
                        <Box className="content px-2">
                            <h1>{item.user.name ?? ''} {index === 0 ? (<span className="mx-2">Most Recent</span>) : (<span className="mx-2">{getDateFromTimestamp(item.comment.timestamp)}</span>)} </h1>
                            {/*<h1>{item.user.name ?? ''}<span className="mx-2">Most Recent</span></h1>*/}
                            <p style={{color: mode === 'light' ? 'black' : 'white' }} className="py-2">{item.comment.message}</p>
                            {/*<Box className="like_details d-flex align-items-center">*/}
                            {/*    <Image src="img/icon/like.svg" alt="avatar1" mr="5px"/>*/}
                            {/*    <p>(01)</p>*/}
                            {/*</Box>*/}
                        </Box>
                    </Flex>
                </div>
            ))}
            {/*<div className="divider my-4"></div>*/}
              {/*<HStack justifyContent="center" w="100%">*/}
              {/*      <Button className="btn load_more-btn">Load more</Button>*/}
              {/*</HStack>*/}
        </Wrapper>
    )
};
const Wrapper = styled.div`
background-color: ${(props) => props.mode === 'light' ?  '#FFFFFF' : 'rgba(128, 88, 4, 0.7)'};
border-radius:10px;
.live-chat{
    
    .top-bar{
        h2{
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }
    }
    .filter-btn{
        border: 1px solid rgba(255, 193, 0, 1);
        border-radius: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
        padding: 7px 20px;
    }
}
    
.list_of_comments{
    .content{
        h1{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-align: left;
            color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
            span{
                padding: 7px 20px;
                border-radius:20px;
                background-color: ${(props) => props.mode === 'light' ?  '#FFC100' : 'rgba(111, 78, 8, 1)'} ;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.04em;
                text-align: center;
                color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
            }
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
                word-break:break-all;
            }
        }

    }
    .like_details{
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.04em;
            text-align: left;
            color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
        }
    }

}
.load_more-btn{
        border: 1px solid rgba(255, 193, 0, 1);
        color: rgba(255, 193, 0, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-align: center;
        width:142px;
        height:43px;
        border-radius: 9px;
    }
    
    
`
export default LiveChat;