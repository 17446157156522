import React, {createContext, useContext, useEffect, useState} from 'react';
import useRouter from "./useRouter";
import UserProvider from "./UserProvider";
import NetworkingAPI from "./NetworkingAPI";

// Create a context for the auth provider
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const router = useRouter();

    const checkUser = async () => {
        try {
            const user = await UserProvider.getCurrentUser(false);
            if (user) {
                setUser(user);
            } else {
                setUser(null);
                router.push('/login');
            }
        } catch (error) {
            console.error('Error checking user:', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        localStorage.clear()
        router.push('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const canRouteForLimitedUser = async () => {
        const currentUser = await UserProvider.getCurrentUser();
        if (currentUser) {
            if (currentUser.limited_user) {
                const restrictedRoutes = ['/dashboard', '/affiliate', '/educators', '/news', '/chatrooms', '/affiliate',]
                if (restrictedRoutes.includes(window.location.pathname)) {
                    console.log('Restricted route:', window.location.pathname);
                    const targetPath = await UserProvider.getLimitedUsersCourses();
                    if (targetPath) {
                        router.push(`/education/${targetPath}`);
                    } else {
                        logout();
                    }
                } else {
                    if (window.location.pathname === '/education') {
                        const targetPath = await UserProvider.getLimitedUsersCourses();
                        if (targetPath) {
                            router.push(`/education/${targetPath}`);
                        } else {
                            logout();
                        }
                    }
                }
            }
        }
    }



    // Run the checkUser function when the component mounts
    useEffect(() => {
        checkUser();
    }, []);

    useEffect(() => {
        if (user && user.isAuthenticated) {
            router.push(`/login`);
        }
    },[]);

    canRouteForLimitedUser();

    return (
        <AuthContext.Provider value={{ user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
