import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import {CreateNewCourseModal} from "./CreateNewCourseModal";
import UserProvider from "../../../hooks/UserProvider";


const Volume = (props) => {

    const { volumes, mode, toggleMode } = props;

    const [isAdmin, setIsAdmin] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const wrapperRef = useRef(null);
    const [count, setCount] = useState(0); // State for triggering re-render

    const params = useParams();
    const navigate = useNavigate();

    const selectedVolume = (volume) => {
        navigate(`/education/${params.educatorId}/${volume.volume._id}/modules`);
    };

    function handleCreateNewCourse() {
        setShowModal(!showModal)
    }

    async function checkUserPermissions() {
        const currentUser = await UserProvider.getCurrentUser();
        if (currentUser.type === 'admin') {
            setIsAdmin(true)
        }
    }

    useEffect(() => {
        checkUserPermissions()
        if (wrapperRef.current) {
            setCount(prevCount => prevCount + 1);
        }
    },[])

    //
    return (
        <>
            {toggleMode && (
                <div ref={wrapperRef} className='volume-area p-4'>
                    <Wrapper>
                        <div className="top-bar d-flex justify-content-between align-items-center">
                            <h1>Courses {volumes ? `(${(volumes.length)})` : ''}</h1>
                            <div>
                                <button type="button" className='btn'>See all</button>
                                { isAdmin && (
                                    <button style={{color: "black"}} onClick={() => handleCreateNewCourse()}
                                            type="button" className='btn'>Create New Course</button>
                                )}
                            </div>
                        </div>
                        <div className="row volume-content">
                        {volumes && volumes.map((items, id) => {
                                return (
                                    <div key={id} className='col-4 py-2' onClick={() => selectedVolume(items)}>
                                        <div className="card" style={{minHeight: '435px'}}>
                                            <img style={{minHeight: '240px', maxHeight: '240px', objectFit: 'cover', borderTopLeftRadius: '26px', borderTopRightRadius: '26px'}}
                                                 src={items.volume ? (items.volume.thumbnail ? items.volume.thumbnail : '/img/course/ModuleThumbnail_Placeholder.png') : '/img/course/ModuleThumbnail_Placeholder.png'}
                                                 alt="pic" className='card-image'/>
                                            <div className="card-body">
                                                <h1 className="card-title" style={{
                                                    maxHeight: '3rem',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-ms-flexbox',
                                                    WebkitLineClamp: 2
                                                }}>{items.volume.title ?? ''}</h1>
                                                <p className="card-text py-2" style={{
                                                    maxHeight: '3rem',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-ms-flexbox',
                                                    WebkitLineClamp: 2
                                                }}>{items.volume.description ?? ''}</p>
                                                <div className="row pt-2">
                                                    <div className="col-6">
                                                        <div className="left-content d-flex align-items-center">
                                                            <img src="img/course/book.svg" alt=""/>
                                                            <p>{(items.modules ?? []).length} Lessons</p>
                                                        </div>

                                                    </div>
                                                    {/*<div className="col-6">*/}
                                                    {/*    <div className="right-content d-flex align-items-center">*/}
                                                    {/*        <IoMdTime/>*/}
                                                    {/*            <p>{items.volume.duration ?? '0:00'}</p>*/}
                                                    {/*        </div>*/}

                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <CreateNewCourseModal show={showModal} />
                    </Wrapper>
                </div>
            )}
        </>
    )
}

const Wrapper = styled.div`
    p {
        margin: 0;
        padding: 0;
    }

    .player-prev {

        img {
            width: 100%;
            object-fit: cover;
        }

        .overly {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        button {
            border: 1px solid rgba(255, 255, 255, 1);
            background: transparent;
            height: 50px;
            width: 50px;
            border-radius: 50px;

            svg {
                color: #fff;
            }
        ;
        }
    }

    .left-content-box {
        width: 100%;
        height: 100vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .right-content-box {
        width: 434px;
        position: relative;
    }

    .player_box {
        background: linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%);
        height: auto;
        border-radius: 40px;
        width: 100%;
    }

    .right-sidebar {
        border-radius: 40px;
        top: 120px;
        background: linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%);

        .top-bar {
            background: url("/img/course/bg.png") no-repeat;
            background-size: cover;
            height: 220px;
            width: 100%;

            .cover-photo {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 220px;
                    border-radius: 40px;
                    border: 1px solid rgba(255, 193, 0, 1);
                }
            }
        }

        .profile {
            background: rgba(101, 70, 5, 1);
            border-radius: 100px;
            padding: 5px;
            position: relative;
            margin-top: -75px;
        }

        .profile-info {
            .name {
                padding: 5px 0;

                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0.04em;
                    text-align: left;
                }

                img {
                    height: 20px;
                    width: 20px;
                    margin-left: 5px;
                }
            }

            .role {
                h3 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: rgba(255, 193, 0, 1);
                }
            }
        }
    }

    .lesson-content {
        .top-content {
            padding-top: 20px;

            h2 {
                font-size: clamp(1rem, 0.403vw + 0.919rem, 1.625rem);
                font-weight: 600;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: left;
            }

            button {
                font-size: 1rem;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(255, 255, 255, 1);
            }

            .details {
                padding-top: 10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .lesson-button {
            button {
                .count {
                    background: url("/img/icon/shape.svg") no-repeat ;
                background-size:cover;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                h4{
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
            }

            .title{
                h2{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    padding-bottom:5px;
                }
                p{
                    color: rgba(122, 202, 0, 1);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;

                }
            }
        }
        .active{
            background:rgba(254, 196, 79, 1);
            border: 0;
            .count{
                background:url("/img/icon/shape.svg") no-repeat ;
                background-size:cover;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                h4{
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(0, 0, 0, 1);
                }
            }

            .title{
                h2{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    padding-bottom:5px;
                    color: rgba(0, 0, 0, 1);
                }
                p{
                    color: rgba(0, 0, 0, 1);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;

                }
            }
        }
        }
    }
.calender-area{
    .btn-prev,.btn-next{
        border: 2px solid rgba(255, 193, 0, 1);
        color: rgba(255, 193, 0, 1);
        font-size:18px;
        border-radius:10px;
    }
    h2{
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0.04em;
        text-align: left;

    }
    .calender_group{
        padding:10px 0;
        button{
            .name{
            color: rgba(173, 137, 25, 1);
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
        }
        .day{
            color: rgba(173, 137, 25, 1);
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
        }
        }
        .active{
            background-color: linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%);
            border-color:rgba(255, 193, 0, 1);
        .name{
            color: rgba(255, 193, 0, 1);
        }
        .day{
            color: rgba(255, 193, 0, 1);
        }
        }
    }
}    
.slick-arrow{
    display: none !important;
}
.divider{
    background-color:rgba(169, 115, 5, 1);
    height: 2px;
    width: 100%;
}
.upcoming-class{
    h1{
        font-size: 20px;
        padding:5px 0;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0.04em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
    }
    button{
        height: 100%;
        img{
            height: 71px;
            width: 71px;
            object-fit: cover;
        }
        h2{
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            padding-bottom:5px;
        }
        h4{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(254, 196, 79, 1);
        }
    }
}
.video-details{
    h1{
        font-size: 26px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        padding-bottom:10px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        padding-bottom:10px;
    }
    .gallery-info{
        padding-left:10px;
        padding-top:10px;
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
}
.volume-area{
    //background: linear-gradient(284.59deg, rgba(55, 43, 4, 0.4) -11.25%, rgba(254, 196, 79, 0.4) 110.63%);
    border: 1px solid #FFC100;
    border-radius:25px;
    .top-bar{
        padding-bottom:20px;
        h1{
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color:rgba(255, 193, 0, 1);
        }
        button{
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }
    }
    .volume-content{
        .card{
            background-color:rgba(51, 39, 12, 1);
            border-radius: 26px;
            cursor:pointer;
        }
        .card-title{
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color:  rgba(255, 255, 255, 1);
        }
        .card-text{
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(225, 225, 225, 1);
        }
        .left-content{
            p{
                padding-left:5px;    
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
            color:rgba(184, 184, 184, 1);
            }
        }
        .right-content{
            svg{
                color: rgba(184, 184, 184, 1);
            }
            p{
                padding-left:5px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
            color:rgba(184, 184, 184, 1);
            }
        }
    }
}
`

export default Volume;