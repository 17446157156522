import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	AngleLeftIcon,
	AngleRightIcon,
	CheckedIcon,
} from "../../../components/Icon";
import { baseURL, corsEverywhere } from "../../../hooks/paths";

const EducationSidebar = () => {
	const [active, setActive] = React.useState(1);
	const [educator, setEducator] = useState();
	const [educatorClasses, setEducatorClasses] = useState([]);

	const [searchParams] = useSearchParams();
  	const courseId = searchParams.get('id');

	const fetchCourse = () => {

	};

	const fetchEducator = (uploader) => {
		if (uploader) {
			const url = corsEverywhere + baseURL + 'educators/educator';
			try {
				fetch(url, {
					method: 'POST',
					headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({educatorId: uploader})
				}).then(async (response) => {
					if (response.ok) {
						const responseJSON = await response.json();
						const data = responseJSON.data;
						if (data != undefined && data != null) {
							console.log(data);
							setEducator(data);
							fetchEducatorClasses();
						} else {
							console.log('Failed to fetch educator');
						};
					}
				})
			} catch(error) {
				console.log(error);
			}
		}
	};

	const fetchEducatorClasses = (uploader) => {
		if (uploader) {
			const url = corsEverywhere + baseURL + 'educators/educator-classes';
			try {
				fetch(url, {
					method: 'POST',
					headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({educatorId: uploader})
				}).then(async (response) => {
					if (response.ok) {
						const responseJSON = await response.json();
						const data = responseJSON.data;
						if (data != undefined && data != null) {
							console.log(data);
							setEducatorClasses(data);
						} else {
							console.log('Failed to fetch educator');
						};
					}
				})
			} catch(error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		fetchCourse();
	},[]);
	
	return (
		<div className="author-sidebar">
			{educator && (
				<div className="author-area">
				<img
					src="/img/education/sidebar-author-banner.png"
					className="banner"
					alt=""
				/>
				<div className="author-img">
					<img src="/img/education/author.png" alt="" />
				</div>
				<div className="text-center">
					<h5 className="name m-0 d-flex justify-content-center align-items-center gap-2 mt-3">
						educator.name <CheckedIcon color="var(--base)" />{" "}
					</h5>
					<div className="text-base text-20">Educator</div>
				</div>
				<div className="d-flex flex-wrap justify-content-between align-items-center pb-4 mb-1 pt-4 mt-1">
					<button className="no-gutter" type="button">
						<AngleLeftIcon />
					</button>
					<h5 className="m-0">January</h5>
					<button className="no-gutter" type="button">
						<AngleRightIcon />
					</button>
				</div>
				<Swiper slidesPerView={4} spaceBetween={10} className="date-slider">
					{[
						Array.from({ length: 31 }, (_, i) => i + 1).map(
							(item, index) => (
								<SwiperSlide key={index}>
									<button
										className={`date-slider-item ${
											index === active ? "active" : ""
										}`}
										onClick={() => setActive(index)}
									>
										<div className="day mb-3">Mon</div>
										<div>06</div>
									</button>
								</SwiperSlide>
							)
						),
					]}
				</Swiper>
				<div
					style={{
						borderBottom: "1px solid #A97305",
						marginBlock: "31px 26px",
					}}
				/>
			</div>
			)}
			<h5 className="title text-base px-2 mb-2">Upcoming Class</h5>
			<div className="upcoming-classes px-2">
				{upcoming.map((item, index) => (
					<Link className="item" key={index} to="#">
						<img src={item.img} alt="" />
						<div className="cont">
							<div className="title text-white">{item.title}</div>
							<div className="tutor">{item.tutor}</div>
						</div>
					</Link>
				))}
			</div>
			<br />
			<br />
		</div>
	);
};

const upcoming = [
	{
		img: "/img/education/upcoming1.png",
		title: "Build your personal brand",
		tutor: "Albert Flores",
	},
	{
		img: "/img/education/upcoming2.png",
		title: "Build your personal brand",
		tutor: "Albert Flores",
	},
	{
		img: "/img/education/upcoming3.png",
		title: "Build your personal brand",
		tutor: "Albert Flores",
	},
	{
		img: "/img/education/upcoming4.png",
		title: "Build your personal brand",
		tutor: "Albert Flores",
	},
];

export default EducationSidebar;
