import { Box, Button, Flex, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoCamera, IoClose } from "react-icons/io5";
import { Modal } from './ProfileDetails';
import ProfilePhotoModal from "./ProfilePhotoModal";


const SettingsSidebar = ({ profile, showModal, mode, toggleMode }) => {

    const [show, setShow] =useState(false);

    useEffect(() => {
        console.log(profile.profile)
    },[]);

    return (
        <div>
            <Wrapper mode={mode}>
                <VStack p="15px">
                    <Stack position="relative" className='top-bar' style={{ width: '100%' }}>
                        <div style={{ maxHeight: '200px', minHeight: '200px', height: '200px', width: '100%', minWidth: '100%'}}>
                            <Image src={profile.profile.profile.cover_photo ? (profile.profile.profile.cover_photo) : "img/setting/1.png"} alt='bg' style={{ borderRadius: '12px', height: '100%', width: '100%', objectFit: 'cover'}} />
                        </div>
                        {/*<Image src={"img/setting/1.png"} alt='bg' w="100%" h={'200px'} objectFit={'cover'} style={{ borderRadius: '12px'}}/>*/}
                        <Box position="absolute" top="0" left="0" w="100%" display="flex" alignItems="start" justifyContent="flex-end" paddingTop="10px" paddingRight="10px">
                            <Button className='btn' onClick={() => showModal('cover')}><IoCamera className='mx-2' /> Change Cover </Button>
                        </Box>
                    </Stack>
                    <Stack className='profile' marginTop="-80px" position="relative" zIndex="10">
                        <Box backgroundImage="url(/img/setting/circle-dil.png)" height="140px" width="140px" backgroundSize="cover" display="flex" alignItems="center" justifyContent="center">
                            <Image height={95} width={95} style={{ borderRadius: '100px'}} src={profile.profile.profile.photo ? (profile.profile.profile.photo) : "/img/blankUser.png"}  alt='profile' />
                        </Box>
                        <Button className='btn' position="absolute" bottom="27px" right="29px" height="30px" width="30px" borderRadius="50px" background={"rgba(255, 193, 0, 1)"} _hover={{background:"rgba(255, 193, 0, 1)"}} color="#fff" p="0" onClick={() => showModal('profile')}>
                            <IoCamera  />
                        </Button>
                    </Stack>
                    <Stack py="10px">
                        <Box className="name">
                                <Text textAlign="center" fontSize="20px" fontWeight="700" lineHeight="24px" color="rgba(255, 255, 255, 1)" letterSpacing="5%" paddingBottom="10px">{profile.profile.profile.name}</Text>
                                <Text textAlign="center" fontSize="14px" fontWeight="400" lineHeight="16.8px" color="rgba(255, 193, 0, 1)" letterSpacing="5%">Principles Academy Student</Text>
                            </Box>
                    </Stack>
                    <HStack justifyContent="space-between" alignItems="center" w="100%" pb="0px">

                    {/*<Box display="flex" flexDir="column" justifyContent="center" alignItems="center" height="97px" width="97px" backgroundImage="url(/img/setting/circle-conc.png)"  backgroundSize="cover">*/}
                    {/*        <Text fontSize="18px" fontWeight="700" lineHeight="24px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%">{profile.profile.social.following}</Text>*/}
                    {/*        <Text textAlign="center" fontSize="14px" fontWeight="400" lineHeight="16.8px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%">Following</Text>*/}
                    {/*    </Box>*/}


                    {/*    <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" height="97px" width="97px" backgroundImage="url(/img/setting/circle-conc.png)"  backgroundSize="cover">*/}
                    {/*        <Text fontSize="18px" fontWeight="700" lineHeight="24px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%">20K</Text>*/}
                    {/*        <Text textAlign="center" fontSize="14px" fontWeight="400" lineHeight="16.8px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%">Followers</Text>*/}
                    {/*    </Box>*/}

                    {/*    <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" height="97px" width="97px" backgroundImage="url(/img/setting/circle-conc.png)"  backgroundSize="cover">*/}
                    {/*        <Text fontSize="18px" fontWeight="700" lineHeight="24px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%">{profile.profile.social.balance}</Text>*/}
                    {/*        <Text textAlign="center" fontSize="14px" fontWeight="400" lineHeight="16.8px" color={mode === 'light' ? 'black' : "rgba(255, 255, 255, 1)"} letterSpacing="5%"> BALANCE</Text>*/}
                    {/*    </Box>*/}
                    </HStack>
                    {profile.profile.social.bio && (
                        <Stack className='bio p-3' >
                            <div className="top-bar">
                                <Text className='title'>Bio </Text>
                            </div>
                            <Box style={{ width: '100%'}} className="divider"></Box>
                            <Text className="description">{profile.profile.social.bio}</Text>
                        </Stack>
                    )}
                    <Stack className='account-removal ' py="10px">
                        <Box className='account-removal-content p-3'>
                            <Text className='title pb-2'>Deactivate My Account</Text>
                            <Text className='details'>Disabling your account means you can recover it at any
time after taking this action.</Text>
                            <HStack justifyContent="space-between" alignItems="center" pt="10px">
                                {/*<Button className='btn disable-btn'>Disable Account</Button>*/}
                                {/*<Button className='btn delete-btn'>Delete Account</Button>*/}
                            </HStack>
                        </Box>
                    </Stack>
                </VStack>
            </Wrapper>
        </div>
    );
};

const Wrapper = styled.div`
    background: ${(props) => props.mode === 'light' ? 'rgba(255, 255, 255, 1)' : 'linear-gradient(180.2deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%)'};
    height: 100%;
    border: ${(props) => props.mode === 'light' ? '0px solid rgba(255, 193, 0, 1)' : '1px solid rgba(255, 193, 0, 1)'};
    border-bottom-color: rgba(255, 191, 0, 0.6274509804);
    border-radius: 20px;

    .top-bar {
        button {
            border: 1px solid rgba(255, 193, 0, 1);
            color: rgba(255, 193, 0, 1);
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .profile {
        svg {
            color: #fff;
            font-size: 18px;
        }
    }

    .bio {
        border-width: 1px;
        border-style: solid;
        border-radius: 20px;
        border-color: #FFC100;

        .divider {
            border: 1px dashed #7d6536;
        }

        .title {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }

        .description {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(243, 243, 243, 1);
            word-break: break-all;
        }
    }

    .account-removal {
        .account-removal-content {
            background-color: rgba(255, 193, 0, 1);
            border-radius: 10px;

            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.05em;
                text-align: left;
                color: black;
            }

            .details {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.05em;
                text-align: left;
                color: black;
                word-break: break-all;
                padding-bottom: 10px;
            }

            .disable-btn {
                background-color: black;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                height: 45px;
                border-radius: 10px;
            }

            .delete-btn {
                background-color: transparent;
                border: 1px solid rgba(0, 0, 0, 1);
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: black;
                height: 45px;
                border-radius: 10px;
            }
        }

    }
`
export default SettingsSidebar;
