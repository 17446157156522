import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Flex, HStack, Image, Text, VStack} from "@chakra-ui/react";
import {IoClose} from "react-icons/io5";
import styled from "styled-components";
import NetworkingAPI from "../../../hooks/NetworkingAPI";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import FirebaseMultimediaUploader from "../../../util/FirebaseMultimediaUploader";

export const CreateNewModuleModal = (props) => {

    const params = useParams();

    const [showModal, setModal] = useState(props.show);
    const fileUploadRef = useRef(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [multimedia, setMultimedia] = useState();
    const [base64String, setBase64String] = useState('');
    const [image, setImage] = useState();

    async function handleUpload() {
        if (!title) {
            return
        }
        try {
            let downloadURL;
            if (multimedia) {
                downloadURL = await FirebaseMultimediaUploader.uploadFile([multimedia]);
            }
            const payload = {
                title: title,
                description: description,
                image: base64String,
                uploader: params.educatorId,
                course: params.courseId,
                downloadURL: downloadURL ?? ''
                // file: image
            }
            const dataResponse = await NetworkingAPI.fetchData('educators/create-module', 'POST', payload);
            if (dataResponse.data) {
                toast.success('Successfully created module')
                resetAndHideModal()
            }
        } catch (e) {
            console.error(e);
        }
    }

    function resetAndHideModal() {
        setTitle('')
        setDescription('')
        setBase64String('')
        setModal(false);
    }

    useEffect(()=>{
        setModal(props.show)
    }, [props.show])

    function handleFileUpload(event) {
        const file = event.target.files[0];
        setMultimedia(file)
        setImage(URL.createObjectURL(file));
    }

    function showPhotoPicker() {
        fileUploadRef.current.click();
    }

    return(
        <div>
            {
                showModal?
                    <Wrapper>
                        <HStack className="modal-area " pos="fixed" top="0" left="0" right="0" w="100%" h="100%" background={"rgb(0 0 0 / 85%)"} zIndex="9999" alignItems="center" justifyContent="center" >
                            <VStack h="557px" w="768px" background={"linear-gradient(20deg, #FFC100 -92.17%,rgba(55, 43, 4, 0.4) 126.39%)"} borderRadius="43px" className="p-5" pos="relative" zIndex="99" justifyContent="space-between" >
                                <Flex justifyContent="space-between" alignItems="start" w="100%" mb="15px">
                                    <Text className="upload-title">
                                        Create New Module
                                    </Text>
                                    <Button className="btn   upload-btn-close" onClick={()=> setModal(false)}>
                                        <IoClose />
                                    </Button>
                                </Flex>
                                <Flex borderRadius="20px" w="100%">
                                    <input onChange={(e) => setTitle(e.target.value)} placeholder={"Title"} style={{width: '100%'}}/>
                                </Flex>
                                <Flex borderRadius="20px" w="100%">
                                    <input  onChange={(e) => setDescription(e.target.value)} placeholder={"Description"} style={{width: '100%'}}/>
                                </Flex>
                                <Flex border="1px dashed rgba(255, 193, 0, 1)" h="294px" borderRadius="20px" w="100%" alignItems="center" justifyContent="center">
                                    <Box onClick={() => showPhotoPicker()}>
                                        <div className="form-group text-center">
                                            <input ref={fileUploadRef} type="file" className="d-none" id="fileUpload" onChange={(e) => handleFileUpload(e)} />
                                            { !multimedia && (
                                                <>
                                                    <label htmlFor="fileUpload" className="text-center">
                                                        <Image src="/img/setting/upload.svg" alt="upload"/>
                                                        <Text className="browse-txt">Browse Files to upload</Text>
                                                    </label>
                                                    <Text className="fileSize-txt">Max file size: 24MB</Text>
                                                    <Text className="fileFormat-txt">Support file type: PNG, JPG</Text>
                                                </>
                                            )}
                                            { multimedia && (
                                                <>
                                                    <Image style={{ width: '100%', height: '280px'}} src={image} alt="upload"/>
                                                </>
                                            )}
                                        </div>
                                    </Box>
                                </Flex>
                                <Flex w="100%" textAlign="center" justifyContent="center" >
                                    <Button className="btn save-btn" onClick={()=> handleUpload()}>Publish</Button>
                                </Flex>
                            </VStack>
                        </HStack>
                    </Wrapper>
                    :""
            }

        </div>
    )
}

const Wrapper = styled.div`
.profile-details-area{
    background: linear-gradient(
    180.2deg,
    rgba(55, 43, 4, 0.4) -38.07%,
    rgba(255, 170, 0, 0.4) 88.45%
  );
  border: 1px solid rgba(255, 193, 0, 1);
  border-radius:16px;
}
.my-subscriptions{
    .title{
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
    }
    .package-academy{
        height: 111px;
        background-repeat: no-repeat;
        background-size: cover;
        .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(0, 0, 0, 1);
        }
        .pro{
            padding: 5px 10px;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
            border-radius: 5px;
        }
        .price{
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(0, 0, 0, 1);
        }
        .edit-subscription{
           width:160px;
           height: 45px;
           border-radius: 5px;
           display: flex;
           justify-content: center;
           align-items: center;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
        }
    }
}
.your-course{
    .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
        }
    .course-items{

        .top-image{
            height: 213px;
            width: 100%;
            object-fit: cover;
        }
        border-radius: 25px;
        overflow: hidden;
            .body-content{
                background-color: rgba(51, 39, 12, 1);
                .course-title{
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
                .course-details{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(225, 225, 225, 1);
                }

                .course-time{
                    img{
                        width: 21px;
                        height: 21px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(184, 184, 184, 1);
                    }
                }
            }
    }
}
.divider{
    background: rgba(255, 180, 89, 1);
    height: 2px;
    width: 100%;
    margin:15px 0;
}
.payment-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05em;
    text-align: left;
}
.payment-method{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}
.payment-details{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 193, 0, 1);
}
.payment-btn{
    width:187px;
    height:45px;
    border-radius: 5px;
    background-color: rgba(166, 126, 0, 1);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
table{
    background-color: transparent;
    border:0;
    tr{
        background-color: transparent;
        border:0;

    }
    tbody{
        th{
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
    td{
        background-color: transparent;
        border:0;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    th{
        background-color: transparent;
        border:0;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .complete-btn{
        background-color: rgba(18, 132, 77, 0.4);
        color: rgba(123, 250, 188, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
    .pending-btn{
        background: rgba(255, 193, 0, 0.3);
        color: rgba(255, 193, 0, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }    .failed-btn{
        background: rgba(255, 93, 66, 0.42);
        color: rgba(255, 164, 149, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
}

  .top-bar {
    height: 83px;
    border-radius: 15px;
    background: linear-gradient(
      143.07deg,
      rgba(55, 43, 4, 0.4) -14.08%,
      rgba(255, 170, 0, 0.4) 34.01%
    );
    .btn {
      width: 160px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      height: 45px;
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 193, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background-color: rgba(255, 193, 0, 1);
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .profile-title {
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    .profile-add {
      background-image: url("/img/setting/circle-dil.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 97.7px;
      width: 97.7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 65px;
        height: 65px;
      }
      .overly {
        position: absolute;
        bottom: 10px;
        left: 8px;
        .btn {
          background-color: rgba(255, 193, 0, 1);
          width: 26px;
          height: 26px;
          border-radius: 25px;
          padding: 0;
          svg {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
  .profile-information{
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
        padding-bottom: 10px;
    }
    input{
        background: #503c11;
        border: 1px solid #FFC100;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(166, 126, 0, 1);
        &::placeholder{
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
        }
    }
    #Phone{
        padding-left:65px;
    }
    button{
        &:focus-within{
            border: 0;
        }
        &:focus-within{
            border: 0;
        }
    }
    .react-datepicker-wrapper{
        width: 100%;

    }
    .calender{
            left: 0;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content:center;
            svg{
                color: rgba(255, 193, 0, 1);
                font-size: 18px;
            }
    }
    .react-datepicker__input-container{
        input{
            width:100% !important;
            padding-left:45px;
            border-radius: 10px;
        }
    }
    .password-area{
        svg{
            color: rgba(254, 196, 79, 1);
            font-size:18px;
        }
    }
    .save-change{
        .btn{
            background-color: rgba(255, 193, 0, 1);
            border-radius: 6px;
            height: 44px;
            width:171px;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
  }
  .twoFactor-information{
    background-color: rgba(134, 91, 7, 1);
    border-radius:11px;
    .form-check-input:checked{
        background-color:rgba(255, 193, 0, 1);
        border:rgba(255, 193, 0, 1);
    }
    .form-switch .form-check-input{
        height: 20px;
        width: 54px;
    }
    .form-group{
        label{
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
            padding-bottom: 10px;
        }
        input{
            background: #503c11;
            border: 1px solid #FFC100;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
            &::placeholder{
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(166, 126, 0, 1);
            }
        }
    }
    .continue-btn{
        background-color: rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
    .canceled-btn{
        border: 1px solid rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
  }
  .invoice-area{
    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
  }
  .modal-area{
    .upload-title{
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
    }
    .upload-btn-close{
        font-size:32px;
        padding: 0;
        svg{
            color: rgba(255, 193, 0, 1);
            font-size: 34px !important;
        }
    }
    .browse-txt{
        font-family: Fira Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 253, 253, 1);
        padding-bottom:30px;
        padding-top:10px;
    }
    .fileSize-txt{
        padding-bottom:7px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .fileFormat-txt{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .save-btn{
        background: linear-gradient(0deg, #FFC100, #FFC100);
        width: 177px;
        height:53px;
        border-radius: 5px;
        border: 1px solid rgba(255, 193, 0, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color:rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
`;

export default CreateNewModuleModal

