import { Box, Button, Card, HStack, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaAngleDown, FaLocationDot } from "react-icons/fa6";
import Slider from "react-slick";
import { corsEverywhere, baseURL } from '../../../hooks/paths';
import { useNavigate } from 'react-router-dom';
import NetworkingAPI from "../../../hooks/NetworkingAPI";

const EducatorSlider = (props) => {

  const { educators } = props;

  const navigate = useNavigate();

  const educatorSelected = (educator) => {
    navigate(`/education/${educator.educator._id}`);
  };

  const navigateToEducatorProfile = (educator) => {
    navigate(`/profile/${educator.id}`);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nav:true,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  useEffect(() => {
    //
  },[]);

  return (
    <div >
      <Wrapper>
          <div className="best-tutors position-relative">
            <div className="top-bar">
              <div className="d-flex justify-content-between align-items-center px-5 py-5">
                <h1>Subscribed Educators</h1>
                <div>
                <Menu>
                    {/*<MenuButton as={Button} rightIcon={<FaAngleDown />}>*/}
                    {/*  Actions*/}
                    {/*</MenuButton>*/}
                    <MenuList className='dropdown'  zIndex="10" >
                      <MenuItem>Download</MenuItem>
                      <MenuItem>Create a Copy</MenuItem>
                      <MenuItem>Mark as Draft</MenuItem>
                      <MenuItem>Delete</MenuItem>
                      <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="overly">
              <Image src="/img/educators/bg.png" alt="educators" w="100%" h="100%" objectFit="cover" />
            </div>
            <div className="slider px-5 pb-5">
          <Slider {...settings}>
            { educators && educators.map((items,id)=>{
                return (
                  <div className='p-3' key={id} onClick={() => educatorSelected(items)} style={{cursor: 'pointer'}}>
                    <HStack className={items.id % 2 ===0 ? ' slider-items':'active slider-items' }>
                      <Card>
                        {/* Top Image */}
                        <Image style={{height: '120px'}} src={items.education ? items.education.banner ?? '' : ''} alt='pic'/>
                        <Box py="10px">
                            <h1 className='name pt-2'>{items.educator.name ?? ''}</h1>
                            <p className='details py-2'>{items.educator.bio ?? ''}</p>
                            <Box className="location" display="flex" alignItems="center" justifyContent="flex-start">
                              <FaLocationDot />
                              <p>{items.educator.location}</p>
                            </Box>
                        </Box>
                      </Card>
                    </HStack>
                  </div>
                )
              })
            }
          </Slider>
          </div>
          </div>
      </Wrapper>
    </div>
  );
};

const Wrapper =styled.div`
.best-tutors{
  background: linear-gradient(180.2deg, rgba(55, 43, 4, 0.4) -38.07%, rgba(255, 170, 0, 0.4) 88.45%);
  border-radius: 28px;
  .top-bar{
    h1{
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0.04em;
      text-align: left;
      color: rgba(255, 193, 0, 1);
    }
    button{
      background-color:rgba(255, 193, 0, 0.4);
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.04em;
      text-align: left;
      color: rgba(255, 193, 0, 1);
      border: 0;
      padding: 8px 25px;
      .dropdown{
        position: relative;
        left: -29px;
        
        }
      }
      .css-1qq679y{
        border-radius: 10px;
      }
      .dropdown{
        border-radius: 10px;
      }
      .chakra-menu__menuitem{
        color: #fff;
        background:rgb(159 120 3 / 54%);
      }
    }
    .overly{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

.slider-items{
 
  padding:20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 193, 0, 1);
  background-color:transparent;
  .name{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.04em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
  .details{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
  .location{
    svg{
      color:rgba(255, 193, 0, 1) ;
    }
    p{
      font-family: Fira Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
}
.active{
  background: linear-gradient(92.98deg, rgba(55, 43, 4, 0.4) -7.39%, rgba(255, 170, 0, 0.4) 2.35%);
} 
`
export default EducatorSlider;