import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DashboardLayout from '../../components/DashboardLayout';
import Calender from './components/Calendar';
import Volume from './components/Volume';
import {Link, useNavigate, useParams} from 'react-router-dom';
import UserProvider from "../../hooks/UserProvider";
import NetworkingAPI from "../../hooks/NetworkingAPI";
import CreateNewEbookModal from "./components/CreateNewEbookModal";

const Course = ({mode,toggleMode} ) => {

    const params = useParams();
    const navigate = useNavigate();
    const contentWrapper = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState();
    const [educator, setEducator] = useState();
    const [educatorClasses, setEducatorClasses] = useState();
    const [educatorEbooks, setEducatorEbooks] = useState();
    const [educatorVolumes, setEducatorVolumes] = useState();
    const [initialized, setInitialized] = useState(false);
    const [currentMode, setCurrentMode] = useState('light');
    const [count, setCount] = useState(0); // State for triggering re-render

    async function fetchEducator() {
        const currentUser = await UserProvider.getCurrentUser(false);
        if (currentUser) {
            setUser(currentUser)
        }
		if (params.educatorId) {
            try {
                const dataResponse = await NetworkingAPI.fetchData('educators/educator', "POST", {educatorId: params.educatorId});
                // console.log("dataResponse", dataResponse);
                const data = dataResponse.data;
                if (data) {
                    const fetchedEducator = data.educator;
                    const fetchedVolumes = data.volumes;
                    const fetchedClasses = data.classes;
                    const fetchedEbooks = data.ebooks ?? [];

                    setEducator(fetchedEducator);
                    setEducatorClasses(fetchedClasses);
                    setEducatorVolumes(fetchedVolumes);
                    setEducatorEbooks(fetchedEbooks)
                } else {
                    console.log('Failed to fetch educator');
                }
            } catch (error) {
                console.log('Failed to fetch educator');
            }
		}
	}

    function convertStringTimestampToDate(inputDate) {
        const date = new Date(inputDate);
        // Get month abbreviation and day
        const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        const day = date.getDate();
        // Get hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes();
        // AM or PM
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        // Convert hours to 12-hour format
        hours %= 12;
        hours = hours || 12; // 0 should be treated as 12
        // Format the time
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
        // Format the date
        return `${monthAbbreviation} ${day} @ ${formattedTime}`
    }

    function handleSelectedClass(classObject) {
        console.log(classObject);
    }

    function handleCreateNewEbook() {
        setShowModal(!showModal);
    }

    useEffect(() => {
        fetchEducator();
        setInitialized(true);
    },[]);

    const selectedVolume = (volume) => {
        navigate(`/education/courseid/modules`);
    };

    // useEffect(() => {
    //     setCurrentMode(mode)
    //     if (contentWrapper.current) {
    //         setCount(prevCount => prevCount + 1);
    //     }
    // },[])

    async function handleDownloadEbook(items) {
        const fileURL = items.url
        try {
            const response = await fetch('https://corsavailable.herokuapp.com/' + fileURL, {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/pdf',
                    "Access-Control-Allow-Origin": "",
                    "origin": "",
                    "x-requested-with": "",
                },
            })
            const blob = await response.blob()
            if (blob) {
                console.log(blob);
            }
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                items.title,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch(err) {

        }
    }


    function convertFilenameToName(fileName) {
        // Use regular expression to remove the extension
        return fileName.replace(/\.[^/.]+$/, "");
        // return fileName
    }

    return (
        <div>
            <DashboardLayout title="Education" mode={mode} toggleMode={toggleMode}>

                <Wrapper mode={mode}>
                    {educator && (
                        <div style={{
                            height: 'auto',
                            width: '100%',
                            display: 'flex',
                            marginTop: '40px',
                            paddingLeft: '30px',
                            color: 'black',
                            alignItems: 'center'
                        }}>
                            <Link style={{color: 'black'}} to="/education">
                                <h6 style={{color: 'gray'}}> Education </h6>
                            </Link>
                            <div style={{
                                width: '5px',
                                height: '5px',
                                marginLeft: '8px',
                                marginRight: '8px',
                                backgroundColor: 'black'
                            }}/>
                            <Link style={{color: 'black'}} to="/education">
                                <h6 style={{color: mode === 'light' ? 'black' : 'white'}}> {educator.name} </h6>
                            </Link>
                        </div>
                    )}
                    {count > -1 && initialized && (
                        <div className="d-flex align-items-start">

                            <div className="left-content-box p-4 overflow-y-auto">
                                {/* media bar */}
                                <Volume
                                    toggleMode={mode}
                                    educator={educator}
                                    volumes={educatorVolumes}/>
                            </div>
                            <div className="right-content-box p-4">
                                <div className="right-sidebar p-4" style={{ height: 'calc(100vh - 200px)' }}>
                                    <div className="top-bar">
                                        <div className="cover-photo">
                                            <img
                                                src={educator ? educator.banner_image ?? '/img/course/cover.png' : '/img/blankUser.png'}
                                                alt=""/>
                                        </div>
                                    </div>
                                    <div className="profile-photo d-flex justify-content-center align-items-center">
                                        <div className="profile  d-inline-block">
                                            <img style={{height: '129px', width: '129px', borderRadius: '64.5px'}}
                                                 src={educator ? educator.photo : "/img/course/ModuleBlankUser_Image.png"}
                                                 alt=""/>
                                        </div>
                                    </div>
                                    <div
                                        className="profile-info d-flex flex-column justify-content-center align-items-center">
                                        <div className="name d-flex justify-content-center align-items-center">
                                            <h2>{educator ? educator.name : ''}</h2>
                                            <img src="/img/course/verified.png" alt=""/>
                                        </div>
                                        <div className="role">
                                            <h3>Educator</h3>
                                        </div>
                                    </div>
                                    <div className="calender">
                                        <Calender/>
                                    </div>
                                    <div className="divider my-2">
                                    </div>
                                    {user && user.limited_user === false && (
                                        <div className="upcoming-class">
                                            <h1>Upcoming Classes</h1>
                                            {educatorClasses && educatorClasses.map((items, id) => {
                                                return (
                                                    <div style={{marginBottom: '20px'}} key={id}>
                                                        <button type='button'
                                                                className=" d-flex align-items-center btn">
                                                            <div className="avatar">
                                                                <img src={items.thumbnail} alt="pic"/>
                                                            </div>
                                                            <div className="info px-2">
                                                                <h2>{items.title}</h2>
                                                                <h4>{convertStringTimestampToDate(items.timestamp)}</h4>
                                                            </div>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )}

                                    {user && user.limited_user === true && (
                                        <div className="upcoming-class">
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h1>Ebooks & Resources</h1>
                                                <button style={{color: "black"}}
                                                        onClick={() => handleCreateNewEbook()}
                                                        type="button"
                                                        className='btn'>Upload
                                                </button>
                                            </div>

                                            {educatorEbooks && educatorEbooks.map((items, id) => {
                                                return (
                                                    <div style={{marginBottom: '20px'}} key={id}>
                                                        <button type='button'
                                                                onClick={() => handleDownloadEbook(items)}
                                                                className=" d-flex align-items-center btn">
                                                            {/*<div className="avatar">*/}
                                                            {/*    <img src={items.thumbnail} alt="pic"/>*/}
                                                            {/*</div>*/}
                                                            <div className="info px-2">
                                                                <h2>{convertFilenameToName(items.title)}</h2>
                                                                <h4>{'Download Now'}</h4>
                                                            </div>
                                                        </button>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </Wrapper>
            </DashboardLayout>
            <CreateNewEbookModal show={showModal}/>
        </div>
    );
};

const Wrapper = styled.div`
    p {
        margin: 0;
        padding: 0;
    }

    .player-prev {

        img {
            width: 100%;
            object-fit: cover;
        }

        .overly {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        button {
            border: 1px solid rgba(255, 255, 255, 1);
            background: transparent;
            height: 50px;
            width: 50px;
            border-radius: 50px;

            svg {
                color: #fff;
            }
        ;
        }
    }

    .left-content-box {
        width: 100%;
        height: 100vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .right-content-box {
        width: 434px;
        position: relative;
    }

    .player_box {
        background: linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%);
        height: auto;
        border-radius: 40px;
        width: 100%;
    }

    .right-sidebar {
        border-radius: 40px;
        top: 120px;
        background: ${(props) => (props.mode === 'light' ? 'white' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)')};
        border: ${(props) => (props.mode === 'light' ? '0px solid #FFC100' : '1px solid #FFC100')}; //1px solid #FFC100;

        .top-bar {
            background: url("/img/course/bg.png") no-repeat;
            background-size: cover;
            height: 220px;
            width: 100%;

            .cover-photo {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 220px;
                    border-radius: 40px;
                    border: 1px solid rgba(255, 193, 0, 1);
                }
            }
        }

        .profile {
            background: rgba(101, 70, 5, 1);
            border-radius: 100px;
            padding: 5px;
            position: relative;
            margin-top: -75px;
        }

        .profile-info {
            .name {
                padding: 5px 0;

                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: ${(props) => (props.mode === 'light' ? 'black' : 'white')};
                }

                img {
                    height: 20px;
                    width: 20px;
                    margin-left: 5px;
                }
            }

            .role {
                h3 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: rgba(255, 193, 0, 1);
                }
            }
        }
    }
    
        .lesson-content {
            .top-content {
                padding-top: 20px;
    
                h2 {
                    font-size: clamp(1rem, 0.403vw + 0.919rem, 1.625rem);
                    font-weight: 600;
                    line-height: 31px;
                    letter-spacing: 0em;
                    text-align: left;
                }
    
                button {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
    
                .details {
                    padding-top: 10px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
    
            .lesson-button {
                button {
                    .count {
                        background: url("/img/icon/shape.svg") no-repeat;
                        background-size: cover;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        h4 {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
    
                    .title{
                    h2{
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        padding-bottom:5px;
                    }
                    p{
                        color: rgba(122, 202, 0, 1);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
    
                    }
                }
            }
            .active{
                background:rgba(254, 196, 79, 1);
                border: 0;
                .count{
                    background:url("/img/icon/shape.svg") no-repeat ;
                    background-size:cover;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h4{
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 1);
                    }
                }
    
                .title{
                    h2{
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        padding-bottom:5px;
                        color: rgba(0, 0, 0, 1);
                    }
                    p{
                        color: rgba(0, 0, 0, 1);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
    
                    }
                }
            }
            }
        }
    .calender-area{
        .btn-prev,.btn-next{
            border: ${(props) => props.mode === 'light' ? '3px solid #000000' : '2px solid rgba(255, 193, 0, 1)'};
            color: ${(props) => props.mode === 'light' ? '#000000' : 'rgba(255, 193, 0, 1)'};
            font-size:18px;
            border-radius:14px;
            height: 36px;
            width: 36px;
        }
        h2{
            font-size: 20px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
    
        }
        .calender_group{
            padding:10px 0;
            button{
                .name{
                color: ${(props) => props.mode === 'light' ? '#000000' : 'rgba(173, 137, 25, 1)' };
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
            }
            .day{
                margin-top: 10px;
                color: ${(props) => props.mode === 'light' ? '#000000' : 'rgba(173, 137, 25, 1)' };
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
            }
            }
            .active{
                background-color: ${(props) => props.mode === 'light' ? 'rgba(255, 193, 0, 0.24)' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)'};
                border-color:rgba(255, 193, 0, 1);
            .name{
                //color: rgba(255, 193, 0, 1);
            }
            .day{
                //color: rgba(255, 193, 0, 1);
            }
            }
        }
    }    
    .slick-arrow{
        display: none !important;
    }
    .divider{
        background-color: ${(props) => props.mode === 'light' ? '#000000' : 'rgba(169, 115, 5, 1)'};
        height: 2px;
        width: 100%;
    }
    .upcoming-class{
        h1{
            font-size: 20px;
            padding:5px 0;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color: ${(props) => props.mode === 'light' ? '#000000' : 'rgba(255, 193, 0, 1);'};
        }
        button{
            height: 100%;
            img{
                height: 71px;
                width: 71px;
                object-fit: cover;
            }
            h2{
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? 'black' : 'white')};
                padding-bottom:5px;
            }
            h4{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(254, 196, 79, 1);
            }
        }
    }
    .video-details{
        h1{
            font-size: 26px;
            font-weight: 600;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            padding-bottom:10px;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            padding-bottom:10px;
        }
        .gallery-info{
            padding-left:10px;
            padding-top:10px;
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    .volume-area{
        background: ${(props) => (props.mode === 'light' ? 'white' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)')};
        border: ${(props) => (props.mode === 'light' ? '0px solid #FFFFFF' : '1px solid #FFC100')};
        border-radius:25px;
        .top-bar{
            padding-bottom:20px;
            h1{
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0.04em;
                text-align: left;
                color:rgba(255, 193, 0, 1);
            }
            button{
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(255, 193, 0, 1);
            }
        }
        .volume-content{
            .card{
                background-color: ${(props) => props.mode === 'light' ? '#FFC100' : '#FFC100'};
                border-radius: 26px;
                cursor:pointer;
            }
            .card-title{
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color:  ${(props) => props.mode === 'light' ? '#000000' : '#000000'};
            }
            .card-text{
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ? '#000000' : '#000000'};
            }
            .left-content{
                p{
                    padding-left:5px;    
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
                color: ${(props) => props.mode === 'light' ? '#717171' : '#717171'};
                }
            }
            .right-content{
                svg{
                    color: rgba(184, 184, 184, 1);
                }
                p{
                    padding-left:5px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
                color:rgba(184, 184, 184, 1);
                }
            }
        }
    }
    `

export default Course;
