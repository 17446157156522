import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DashboardLayout from '../../components/DashboardLayout';
import Player from './Player';
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import Calender from './components/Calendar';
import Module from './components/Module';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { baseURL, corsEverywhere } from '../../hooks/paths';
import NetworkingAPI from "../../hooks/NetworkingAPI";
import {data} from "../affiliate/components/AffiliateChart";
import CreateNewLessonModal from "./components/CreateNewLessonModal";
import UserProvider from "../../hooks/UserProvider";
import EditLessonModal from "./components/EditLessonModal";

const SelectedModule = ({mode,toggleMode} ) => {

    const params = useParams();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const [educator, setEducator] = useState();
    const [educatorClasses, setEducatorClasses] = useState();
    const [course, setCourse] = useState();
    const [modules, setModules] = useState();
    const [files, setFiles] = useState();
    const [currentModule, setCurrentModule] = useState();
    const [currentModuleLessons, setCurrentModuleLessons] = useState([]);
    const [currentLessonVideo, setCurrentLessonVideo] = useState();
    const [initialized, setInitialized] = useState(false);

    const [isPlay,setPlay] = useState(false);

    const fetchEducator = async () => {

        if (params.educatorId) {
            try {
                const dataResponse = await NetworkingAPI.fetchData('educators/educator', "POST", {educatorId: params.educatorId});
                if (dataResponse.data) {
                    const data = dataResponse.data;
                    setEducator(data.educator);
                    setEducatorClasses(data.classes ?? [])
                    setInitialized(true)
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const expandModuleVideo = () => {
        const lessonId = currentLessonVideo._id;
        navigate(`/education/${params.courseId}/lesson/${lessonId}`);
    };

    const selectedNewLessonVideo = (video) => {
        setCurrentLessonVideo(video);
        if (!isPlay) {
            setPlay(true);
        }
    }

    const selectedModule = (module) => {
        setCurrentModule(module);
        setCurrentModuleLessons(module.lessons ?? []);
    };

    function convertStringTimestampToDate(inputDate) {
        const date = new Date(inputDate);

        // Get month abbreviation and day
        const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        const day = date.getDate();

        // Get hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes();

        // AM or PM
        const amOrPm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours %= 12;
        hours = hours || 12; // 0 should be treated as 12

        // Format the time
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

        // Format the date
        const formattedDate = `${monthAbbreviation} ${day} @ ${formattedTime}`;

        return formattedDate;
    }

    async function fetchCourseFiles() {
        try {
            const dataResponse = await NetworkingAPI.fetchData('education/course-modules/files', "POST", { courseId: params.courseId });
            if (dataResponse.data) {
                const fetchedModulesFiles = dataResponse.data;
                if (fetchedModulesFiles) {
                    setCourse(fetchedModulesFiles.course)
                    setFiles(fetchedModulesFiles.files)
                } else {
                    console.log('Failed to fetch reference Id');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    async function fetchCourseModule() {
        try {
            const dataResponse = await NetworkingAPI.fetchData('education/course-modules', "POST", { courseId: params.courseId });
            if (dataResponse.data) {
                const fetchedModules = dataResponse.data;
                if (fetchedModules) {
                    setModules(fetchedModules);
                    const first = fetchedModules[0];
                    // console.log(first)
                    setCurrentModule(fetchedModules[0]);
                    setCurrentModuleLessons(fetchedModules[0].lessons ?? []);
                    if (currentModuleLessons) {
                        selectedNewLessonVideo((fetchedModules[0].lessons ?? [])[0])
                    }
                } else {
                    console.log('Failed to fetch reference Id');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleEditLesson() {
        setShowEditModal(!showEditModal)
    }

    function handleCreateLesson() {
        setShowModal(!showModal)
    }

    function handlePlayStarted(e) {
        if (currentLessonVideo) {
            setPlay(true)
        } else {
            if (currentModuleLessons.length > 0) {
                selectedNewLessonVideo(currentModuleLessons[0])
            }
        }
    }

    async function initializePage() {
        await fetchCourseModule()
        await fetchCourseFiles()
        await fetchEducator();
    }

    async function checkUserPermissions() {
        const currentUser = await UserProvider.getCurrentUser();
        if (currentUser.type === 'admin') {
            setIsAdmin(true)
        }
    }

    useEffect(() => {
        checkUserPermissions()
        initializePage();
    },[]);

    return (
        <div>
            <DashboardLayout title="Education" mode={mode} toggleMode={toggleMode}>
                { educator && currentModule && (
                    <div style={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        marginTop: '40px',
                        paddingLeft: '30px',
                        color: 'black',
                        alignItems: 'center'
                    }}>
                        <Link style={{color: 'black'}} to="/education">
                            <h6 style={{color: 'gray'}}> Education </h6>
                        </Link>
                        <div style={{
                            width: '5px',
                            height: '5px',
                            marginLeft: '8px',
                            marginRight: '8px',
                            backgroundColor: 'black'
                        }}/>
                        <Link style={{color: 'black'}} to={`/education/${educator._id}`}>
                            <h6 style={{color: 'gray'}}> {educator.name} </h6>
                        </Link>
                        <div style={{
                            width: '5px',
                            height: '5px',
                            marginLeft: '8px',
                            marginRight: '8px',
                            backgroundColor: 'black'
                        }}/>
                        <h6 style={{color: mode === 'light' ? 'black' : "white"}}> {course.title} </h6>
                    </div>
                )}
                <Wrapper mode={mode}>
                    {initialized && (
                        <div className="d-flex align-items-start">
                            <div className="left-content-box p-4 overflow-y-auto">
                                {/* media bar */}
                                {/* Player Box */}
                                <div className="player_box">
                                    <div className="row p-4">
                                        <div className="col-7" style={{height: 'auto'}}>
                                            {isPlay ? (
                                                <>
                                                    <Player
                                                        currentVideoURL={currentLessonVideo ? currentLessonVideo.url : ''}/>

                                                </>

                                            ) : (
                                                <div className=' position-relative player-prev'>
                                                    <img style={{borderRadius: '32px'}}
                                                         src={ currentModule ? currentModule.module.thumbnail : "/img/course/video_thumbnail.jpg"} alt="thumb"/>
                                                    <div className="position-absolute overly">
                                                        <button type="button" className='btn'
                                                                onClick={() => handlePlayStarted()}>
                                                            <FaPlay/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            }

                                            <div className="video-details py-4">
                                                <h1>{currentModule ? currentModule.module.title : ''}</h1>
                                                <p>{currentModule ? currentModule.module.description : ''}</p>
                                                <div className="avatar-list d-flex align-items-center">
                                                    {/*<div className="gallery">*/}
                                                    {/*    <img src="/img/course/avatarList.png" alt="avatarList"/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="gallery-info">*/}
                                                    {/*    +7 Participants*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="lesson-content">
                                                <div className="d-flex justify-content-end">
                                                    {isAdmin && (
                                                        <>
                                                            <button style={{color: "black"}}
                                                                    type="button" onClick={() => handleEditLesson()}
                                                                    className='btn'>Edit
                                                            </button>
                                                            <button style={{color: "black"}}
                                                                    type="button" onClick={() => handleCreateLesson()}
                                                                    className='btn'>Create New Lesson
                                                            </button>
                                                        </>
                                                    )}
                                                    {currentLessonVideo && (
                                                        <button type="button" className='btn '
                                                                onClick={() => expandModuleVideo()}>
                                                            <img src="/img/icon/share.svg" alt="share"/>
                                                        </button>
                                                    )}
                                                </div>
                                                {/* top-content */}
                                                <div className="top-content">
                                                    {/*<div className="d-flex justify-content-between align-items-center">*/}
                                                {/*        <h2>Lesson Exercise</h2>*/}
                                                {/*        <button type="button" className='btn'>See all</button>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="details">*/}
                                                {/*        <p>Learning journey with our compehesnsive lesson excersize*/}
                                                {/*            coures</p>*/}
                                                {/*    </div>*/}
                                                </div>
                                                {/* lesson */}
                                                <div className="lesson-button pt-2" style={{ overflowY: 'scroll', maxHeight: '500px'}}>
                                                    {currentModuleLessons && currentModuleLessons.map((item, index) => {
                                                        return (
                                                            <button type="button"
                                                                    className={currentLessonVideo ? (currentLessonVideo._id === item._id ? 'btn w-100 active' : 'btn w-100') : 'btn w-100'}
                                                                    id={index}
                                                                    onClick={() => selectedNewLessonVideo(item)}>
                                                                <div
                                                                    className="d-flex align-items-center w-100 justify-content-between">
                                                                    <div className="d-flex align-items-center" >
                                                                        <div className="count" style={{ height: '30px', width: '30px'}}>
                                                                            <h4>{index + 1}</h4>
                                                                        </div>
                                                                        <div className="title ps-3" style={{ maxWidth: '85%', paddingLeft: '15px'}}>
                                                                            <h2>{item.title ?? ''}</h2>
                                                                            <p>{currentLessonVideo ? (currentLessonVideo._id === item._id ? 'Currently Viewing' : 'Tap to play') : 'Tap to play'}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="share">
                                                                        <img src="/img/icon/arrow-up.svg" alt=""/>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='py-5'>
                                    { modules && (
                                        <Module files={files} modules={modules} selectedNewModule={selectedModule} mode={mode}/>
                                    )}
                                </div>
                            </div>

                            <div className="right-content-box p-4">
                                <div className="right-sidebar p-4" style={{ height: 'calc(100vh - 200px)'}}>
                                    <div className="top-bar">
                                        <div className="cover-photo">
                                            <img
                                                src={educator ? educator.banner_image ?? '/img/course/cover.png' : '/img/blankUser.png'}
                                                alt=""/>
                                        </div>
                                    </div>
                                    <div className="profile-photo d-flex justify-content-center align-items-center">
                                        <div className="profile  d-inline-block">
                                            <img style={{height: '129px', width: '129px', borderRadius: '64.5px'}}
                                                 src={educator ? educator.photo : "/img/course/ModuleBlankUser_Image.png"}
                                                 alt=""/>
                                        </div>
                                    </div>
                                    <div
                                        className="profile-info d-flex flex-column justify-content-center align-items-center">
                                        <div className="name d-flex justify-content-center align-items-center">
                                            <h2>{educator ? educator.name : ''}</h2>
                                            <img src="/img/course/verified.png" alt=""/>
                                        </div>
                                        <div className="role">
                                            <h3>Educator</h3>
                                        </div>
                                    </div>
                                    <div className="calender">
                                        <Calender/>
                                    </div>
                                    <div className="divider my-2">
                                    </div>
                                    <div className="upcoming-class">
                                        <h1>Upcoming Class</h1>
                                        {educatorClasses && educatorClasses.map((items, id) => {
                                            return (
                                                <div style={{marginBottom: '20px'}} key={id}>
                                                    <button type='button' className=" d-flex align-items-center btn">
                                                        <div className="avatar">
                                                            <img src={items.thumbnail} alt="pic"/>
                                                        </div>
                                                        <div className="info px-2">
                                                            <h2>{items.title}</h2>
                                                            <h4>{convertStringTimestampToDate(items.timestamp)}</h4>
                                                        </div>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Wrapper>
                <CreateNewLessonModal selectedModule={currentModule} show={showModal} />
                <EditLessonModal selectedModule={currentModule} show={showEditModal} />
            </DashboardLayout>

        </div>
    );
};

const Wrapper = styled.div`
    p {
        margin: 0;
        padding: 0;
    }

    .player-prev {

        img {
            width: 100%;
            object-fit: cover;
        }

        .overly {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        button {
            border: 1px solid rgba(255, 255, 255, 1);
            background: transparent;
            height: 50px;
            width: 50px;
            border-radius: 50px;

            svg {
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            }
        ;
        }
    }

    .left-content-box {
        width: 100%;
        height: 100vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .right-content-box {
        width: 434px;
        position: relative;
    }

    .player_box {
        background: ${(props) => (props.mode === 'light' ? '#FFFFFF' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)')};
        border: ${(props) => (props.mode === 'light' ? '0px solid #FFFFFF' : '1px solid #FFC100')};
        height: auto;
        border-radius: 40px;
        width: 100%;
        color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
    }

    .right-sidebar {
        border-radius: 40px;
        top: 120px;
        border: ${(props) => (props.mode === 'light' ? '0px solid #FFFFFF' : '1px solid #FFC100')};
        background: ${(props) => (props.mode === 'light' ? '#FFFFFF' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)')};

        .top-bar {
            background: url("/img/course/bg.png") no-repeat;
            background-size: cover;
            height: 220px;
            width: 100%;

            .cover-photo {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 220px;
                    border-radius: 40px;
                    border: ${(props) => (props.mode === 'light' ? '1px solid rgba(255, 255, 255, 1)' : '1px solid rgba(255, 193, 0, 1)')};
                }
            }
        }

        .profile {
            background: ${(props) => (props.mode === 'light' ? 'rgba(255, 255, 255, 1)' : 'rgba(101, 70, 5, 1)')};
            border-radius: 100px;
            padding: 5px;
            position: relative;
            margin-top: -75px;
        }

        .profile-info {
            .name {
                padding: 5px 0;

                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')}
                }

                img {
                    height: 20px;
                    width: 20px;
                    margin-left: 5px;
                }
            }

            .role {
                h3 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    color: rgba(255, 193, 0, 1);
                }
            }
        }
    }

    .lesson-content {
        .top-content {
            padding-top: 20px;

            h2 {
                font-size: 36px;
                font-weight: 600;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            }

            button {
                font-size: 1rem;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            }

            .details {
                padding-top: 10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .lesson-button {
            button {
                .count {
                    background: url("/img/icon/shape.svg") no-repeat;
                    background-size: cover;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};

                    h4 {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
                    }
                }

                .title {
                    h2 {
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        padding-bottom: 5px;
                        color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
                    }

                    p {
                        color: rgba(122, 202, 0, 1);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;

                    }
                }
            }

            .active {
                background: rgba(254, 196, 79, 1);
                border: 0;

                .count {
                    background: url("/img/icon/shape.svg") no-repeat;
                    background-size: cover;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h4 {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 1);
                    }
                }

                .title {
                    h2 {
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        padding-bottom: 5px;
                        color: rgba(0, 0, 0, 1);
                    }

                    p {
                        color: rgba(0, 0, 0, 1);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;

                    }
                }
            }
        }
    }

    .calender-area {
        .btn-prev, .btn-next {
            border: 2px solid rgba(255, 193, 0, 1);
            color: rgba(255, 193, 0, 1);
            font-size: 18px;
            border-radius: 10px;
        }

        h2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;

        }

        .calender_group {
            padding: 10px 0;

            button {
                .name {
                    color: rgba(173, 137, 25, 1);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .day {
                    color: rgba(173, 137, 25, 1);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: center;
                }
            }

            .active {
                background-color: linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%);
                border-color: rgba(255, 193, 0, 1);

                .name {
                    color: rgba(255, 193, 0, 1);
                }

                .day {
                    color: rgba(255, 193, 0, 1);
                }
            }
        }
    }

    .slick-arrow {
        display: none !important;
    }

    .divider {
        background-color: rgba(169, 115, 5, 1);
        height: 2px;
        width: 100%;
    }

    .upcoming-class {
        h1 {
            font-size: 20px;
            padding: 5px 0;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }

        button {
            height: 100%;

            img {
                height: 71px;
                width: 71px;
                object-fit: cover;
            }

            h2 {
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
                padding-bottom: 5px;
            }

            h4 {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(254, 196, 79, 1);
            }
        }
    }

    .video-details {
        h1 {
            font-size: 26px;
            font-weight: 600;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
            color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            padding-bottom: 10px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            padding-bottom: 10px;
        }

        .gallery-info {
            padding-left: 10px;
            padding-top: 10px;

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#FFFFFF')};
            }
        }
    }

    .volume-area {
        background: ${(props) => (props.mode === 'light' ? 'white' : 'linear-gradient(180deg, #372B04 0%, #BC7F01 113.4%)')};
        border: ${(props) => (props.mode === 'light' ? '0px solid #FFFFFF' : '1px solid #FFC100')};;
        border-radius: 25px;

        .top-bar {
            padding-bottom: 20px;

            h1 {
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0.04em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : 'rgba(255, 193, 0, 1)')};
            }

            button {
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : 'rgba(255, 193, 0, 1)')};
            }
        }

        .volume-content {
            .card {
                background-color: ${(props) => (props.mode === 'light' ? 'rgba(255, 193, 0, 1)' : 'rgba(255, 193, 0, 1)')};
                border-radius: 26px;
                cursor: pointer;
            }

            .card-title {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                ${(props) => (props.mode === 'light' ? '#000000' : '#000000')};
            }

            .card-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => (props.mode === 'light' ? '#000000' : '#000000')};
            }

            .left-content {
                p {
                    padding-left: 5px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: ${(props) => (props.mode === 'light' ? 'rgba(113, 113, 113,1)' : 'rgba(113, 113, 113,1)')};
                }
            }

            
            
            .right-content {
                svg {
                    color: ${(props) => (props.mode === 'light' ? 'rgba(113, 113, 113,1)' : 'rgba(113, 113, 113,1)')};
                }

                p {
                    padding-left: 5px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: ${(props) => (props.mode === 'light' ? 'rgba(113, 113, 113,1)' : 'rgba(113, 113, 113,1)')};
                }
            }
        }
    }
`
export default SelectedModule;
