import {Box, Button, Flex, HStack, Image, Text} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const Invoices = ({ profile }) =>{

    const invoiceData=[
        {
            name:"Beginner’s Guide to becoming a professional ",
            date:"8/21/1999",
            amount:"500",
            status:"pending",
            pic:"/img/setting/course1.png"
        },
        {
            name:"Beginner’s Guide to becoming a professional ",
            date:"8/21/1999",
            amount:"500",
            status:"pending",
            pic:"/img/setting/course2.png"
        }
        ,
        {
            name:"Beginner’s Guide to becoming a professional ",
            date:"8/21/1999",
            amount:"500",
            status:"pending",
            pic:"/img/setting/course3.png"
        },
        {
            name:"Beginner’s Guide to becoming a professional ",
            date:"8/21/1999",
            amount:"500",
            status:"pending",
            pic:"/img/setting/course1.png"
        }
    ]
    return(
        <div>
            <Wrapper className="invoice-area">
                <HStack justifyContent="space-between" alignItems="flex-end" className="invoice-top-bar py-5">
                    <Box w="50%">
                        <Text className="title pb-2">Resent invoice</Text>
                        <Text wordBreak="break-all" className="details">These are your current subscriptions. They will be billed on the same cycle.
                            You can update any subscription at any time.</Text>
                    </Box>
                    <Flex w="50%" justifyContent="flex-end">
                        <Button className="btn">
                            <Image src="img/setting/download.svg" alt="printer" />
                        </Button>
                        <Button className="btn">
                            <Image src="img/setting/printer.svg" alt="printer" />
                        </Button>
                    </Flex>
                </HStack>
                <HStack>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">Products</th>
                            <th scope="col" className="text-center">Date</th>
                            <th scope="col" className="text-center">Amount</th>
                            <th scope="col" className="text-end">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            invoiceData.map((items,key)=>{
                                return(
                                    <tr>
                                        <th scope="row">
                                            <Flex alignItems="center">
                                                <Image src={items.pic} alt="course1" height="48px" width="48px" borderRadius="10px" mr="15px" objectFit="cover"/>
                                                <Text wordBreak="break-all" w="70%">{items.name}</Text>
                                            </Flex>
                                        </th>
                                        <td className="text-center">{items.date}</td>
                                        <td className="text-center">{items.amount}</td>
                                        <td className="text-end">
                                            <Button className={`btn ${items.status}-btn text-capitalize `} >
                                                {items.status}
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }


                        </tbody>
                    </table>
                </HStack>
            </Wrapper>
        </div>
    )
}


const Wrapper = styled.div`
.profile-details-area{
    background: linear-gradient(
    180.2deg,
    rgba(55, 43, 4, 0.4) -38.07%,
    rgba(255, 170, 0, 0.4) 88.45%
  );
  border: 1px solid rgba(255, 193, 0, 1);
  border-radius:16px;
}
.my-subscriptions{
    .title{
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
    }
    .package-academy{
        height: 111px;
        background-repeat: no-repeat;
        background-size: cover;
        .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(0, 0, 0, 1);
        }
        .pro{
            padding: 5px 10px;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
            border-radius: 5px;
        }
        .price{
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(0, 0, 0, 1);
        }
        .edit-subscription{
           width:160px;
           height: 45px;
           border-radius: 5px;
           display: flex;
           justify-content: center;
           align-items: center;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 1);
        }
    }
}
.your-course{
    .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.05em;
            text-align: left;
            color:rgba(255, 255, 255, 1);
        }
    .course-items{

        .top-image{
            height: 213px;
            width: 100%;
            object-fit: cover;
        }
        border-radius: 25px;
        overflow: hidden;
            .body-content{
                background-color: rgba(51, 39, 12, 1);
                .course-title{
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
                .course-details{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(225, 225, 225, 1);
                }

                .course-time{
                    img{
                        width: 21px;
                        height: 21px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(184, 184, 184, 1);
                    }
                }
            }
    }
}
.divider{
    background: rgba(255, 180, 89, 1);
    height: 2px;
    width: 100%;
    margin:15px 0;
}
.payment-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05em;
    text-align: left;
}
.payment-method{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}
.payment-details{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 193, 0, 1);
}
.payment-btn{
    width:187px;
    height:45px;
    border-radius: 5px;
    background-color: rgba(166, 126, 0, 1);
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
table{
    background-color: transparent;
    border:0;
    tr{
        background-color: transparent;
        border:0;

    }
    tbody{
        th{
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
    td{
        background-color: transparent;
        border:0;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    th{
        background-color: transparent;
        border:0;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .complete-btn{
        background-color: rgba(18, 132, 77, 0.4);
        color: rgba(123, 250, 188, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
    .pending-btn{
        background: rgba(255, 193, 0, 0.3);
        color: rgba(255, 193, 0, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }    .failed-btn{
        background: rgba(255, 93, 66, 0.42);
        color: rgba(255, 164, 149, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        width: 89px;
        height: 26px;
        border-radius: 25px;
    }
}

  .top-bar {
    height: 83px;
    border-radius: 15px;
    background: linear-gradient(
      143.07deg,
      rgba(55, 43, 4, 0.4) -14.08%,
      rgba(255, 170, 0, 0.4) 34.01%
    );
    .btn {
      width: 160px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      height: 45px;
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 193, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background-color: rgba(255, 193, 0, 1);
      border: 1px solid rgba(255, 193, 0, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .profile-title {
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    .profile-add {
      background-image: url("/img/setting/circle-dil.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 97.7px;
      width: 97.7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 65px;
        height: 65px;
      }
      .overly {
        position: absolute;
        bottom: 10px;
        left: 8px;
        .btn {
          background-color: rgba(255, 193, 0, 1);
          width: 26px;
          height: 26px;
          border-radius: 25px;
          padding: 0;
          svg {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
  .profile-information{
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 193, 0, 1);
        padding-bottom: 10px;
    }
    input{
        background: #503c11;
        border: 1px solid #FFC100;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(166, 126, 0, 1);
        &::placeholder{
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
        }
    }
    #Phone{
        padding-left:65px;
    }
    button{
        &:focus-within{
            border: 0;
        }
        &:focus-within{
            border: 0;
        }
    }
    .react-datepicker-wrapper{
        width: 100%;

    }
    .calender{
            left: 0;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content:center;
            svg{
                color: rgba(255, 193, 0, 1);
                font-size: 18px;
            }
    }
    .react-datepicker__input-container{
        input{
            width:100% !important;
            padding-left:45px;
            border-radius: 10px;
        }
    }
    .password-area{
        svg{
            color: rgba(254, 196, 79, 1);
            font-size:18px;
        }
    }
    .save-change{
        .btn{
            background-color: rgba(255, 193, 0, 1);
            border-radius: 6px;
            height: 44px;
            width:171px;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
  }
  .twoFactor-information{
    background-color: rgba(134, 91, 7, 1);
    border-radius:11px;
    .form-check-input:checked{
        background-color:rgba(255, 193, 0, 1);
        border:rgba(255, 193, 0, 1);
    }
    .form-switch .form-check-input{
        height: 20px;
        width: 54px;
    }
    .form-group{
        label{
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
            padding-bottom: 10px;
        }
        input{
            background: #503c11;
            border: 1px solid #FFC100;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(166, 126, 0, 1);
            &::placeholder{
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(166, 126, 0, 1);
            }
        }
    }
    .continue-btn{
        background-color: rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
    .canceled-btn{
        border: 1px solid rgba(255, 193, 0, 1);
        height: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        width:137px;
        color: rgba(255, 255, 255, 1);
    }
  }
  .invoice-area{
    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }
    .details{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
  }
  .modal-area{
    .upload-title{
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
    }
    .upload-btn-close{
        font-size:32px;
        padding: 0;
        svg{
            color: rgba(255, 193, 0, 1);
            font-size: 34px !important;
        }
    }
    .browse-txt{
        font-family: Fira Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 253, 253, 1);
        padding-bottom:30px;
        padding-top:10px;
    }
    .fileSize-txt{
        padding-bottom:7px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .fileFormat-txt{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color:rgba(255, 193, 0, 1);
    }
    .save-btn{
        background: linear-gradient(0deg, #FFC100, #FFC100);
        width: 177px;
        height:53px;
        border-radius: 5px;
        border: 1px solid rgba(255, 193, 0, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color:rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
`;

export default Invoices