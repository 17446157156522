import NetworkingAPI from "./NetworkingAPI";

const UserProvider = {

    async getCurrentUser(shouldRefetch) {
        const storedUserObject = localStorage.getItem('user');
        if (storedUserObject && shouldRefetch) {
            // localStorage.removeItem('user');
            try {
                const payload = {
                    memberId: JSON.parse(storedUserObject)._id
                }
                const dataResponse = await NetworkingAPI.fetchData('auth/fetch-user', "POST", payload);
                if (dataResponse.data) {
                    const data = dataResponse.data;
                    localStorage.setItem('user', JSON.stringify(data));
                    return data;
                } else {

                }
            } catch (e) {
                console.log(e);
                console.log('Failed to fetch company with an error.');
                return undefined;
            }
        } else if (!storedUserObject) {
            return undefined;
        } else {
            return JSON.parse(storedUserObject);
        }
    },

    async getLimitedUsersCourses() {
        const currentUser = await this.getCurrentUser();
        if (!currentUser) {
            return;
        }
        try {
            const dataRequest = await NetworkingAPI.fetchData('educators/educators/', "POST", {memberId: currentUser.id ?? ''});
            const fetchedEducators = dataRequest.data;
            if (fetchedEducators !== undefined) {
                const filteredEducators = fetchedEducators.filter((educator) => educator.educator.limited_user === true);
                console.log(filteredEducators);
                if (!filteredEducators.length > 0) {
                    return;
                }
                return filteredEducators[0].educator._id
            } else {
                return;
            }
        } catch (error) {
            console.log('Error fetching educators:', error);
            return;
        }
    }

};

export default UserProvider;
