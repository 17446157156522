import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout";
import Player from "./Player";
import { FaRegHeart } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import LiveChat from "./components/LiveChat";
import { baseURL, corsEverywhere } from "../../hooks/paths";
import {Link, useNavigate, useParams} from "react-router-dom";
import {mod} from "three/nodes";
import NetworkingAPI from "../../hooks/NetworkingAPI";
import UserProvider from "../../hooks/UserProvider";


const CourseDetails = ({mode,toggleMode} ) => {
  const [isPlay, setPlay] = useState(false);

  const [lesson, setLesson] = useState();
  const [module, setModule] = useState();
  const [upcomingVideos, setUpcomingVideos] = useState();

  const navigate = useNavigate()
	const [lessonLikes, setLessonLikes] = useState(0);
	const [lessonLikeStatus, setLessonLikeStatus] = useState(false);
	const [progress, setProgress] = useState(false);
	const [completedLessons, setCompletedLessons] = useState([]);

	const [educator, setEducator] = useState();
	const [educatorSubscribers, setEducatorSubscribers] = useState(0);

	const params = useParams();

	const memberId = '7eova3MgeMMV9BTE1H86sElTWcA3';

	const handleLikeClick = () => {
		const url = corsEverywhere + baseURL + 'education/toggle-lesson-like';
		try {
			fetch(url, {
				method: 'POST',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({lessonId: params.lessonId, memberId: memberId})
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data != undefined && data != null) {
						setLessonLikeStatus(data);
					} else {
						console.log('Failed to fetch lesson');
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	async function fetchEducator(uploader)  {
		if (uploader) {
			try {
				const dataResponse = await NetworkingAPI.fetchData('educators/educator', 'POST', {educatorId: uploader});
				const data = dataResponse.data;
				if (data) {
					setEducator(data);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	async function fetchLesson() {
		try {
			const dataResponse = await NetworkingAPI.fetchData('education/lesson', 'POST', {lessonId: params.lessonId});
			const data = dataResponse.data;
			if (data) {
				setUpcomingVideos(data.upcoming);
				setModule(data.module);
				setLesson(data.lesson);
				setProgress(data.progress)
				return data.lesson
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function fetchLessonLikes() {
		const currentUser = await UserProvider.getCurrentUser(false);
		if (!currentUser) {
			return;
		}
		try {
			const dataResponse = await NetworkingAPI.fetchData('education/check-video-completion', 'POST', {lessonId: params.lessonId, memberId: currentUser._id});
			const data = dataResponse.data;
			if (data) {
				setCompletedLessons(data.lessons_completed ?? [])
				if ((data.lessons_completed ?? []).includes(params.lessonId)) {
					setProgress(true)
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function fetchLessonLikeStatus()  {

		// try {
		// 	const dataResponse = await NetworkingAPI.fetchData('education/lesson-like-status', 'POST', {lessonId: params.lessonId, memberId: memberId});
		// 	const data = dataResponse.data;
		// 	if (data != undefined && data != null) {
		// 		setLessonLikeStatus(data);
		// 	}
		// } catch (e) {
		// 	console.log(e);
		// }
	}

	const getTimeAgoSinceTimestamp = (timestamp) => {
		return '5 Days Ago'
	};

	async function initializePage() {
		const retrievedLesson = await fetchLesson()
		if (retrievedLesson) {
			await fetchEducator(retrievedLesson.uploader);
		}
		await fetchLessonLikes();
		await fetchLessonLikeStatus();
	}

	function handleUpcomingSelected(_id) {
		const url = `/education/${params.courseId}/lesson/${_id}`
		// navigate(url);
		window.location.replace(url);
	}

	async function handleCompleted() {
		const currentUser = await UserProvider.getCurrentUser(false);
		if (!currentUser) {
			return;
		}
		try {
			const dataResponse = await NetworkingAPI.fetchData('education/mark-video-completed', 'POST', {lessonId: params.lessonId, memberId: currentUser._id});
			const data = dataResponse.data;
			if (data) {
				setCompletedLessons(data.lessons_completed ?? [])
				if ((data.lessons_completed ?? []).includes(params.lessonId)) {
					setProgress(true)
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

    useEffect(() => {
		initializePage();
    },[isPlay]);

	return (
    <div>
      <DashboardLayout title="Education" mode={mode} toggleMode={toggleMode} >
		  {educator && (
			  <Wrapper mode={mode}>
				  <div style={{
					  height: 'auto',
					  width: '100%',
					  display: 'flex',
					  marginTop: '40px',
					  paddingLeft: '30px',
					  color: 'black',
					  alignItems: 'center'
				  }}>
					  <Link style={{color: 'black'}} to="/education">
						  <h6 style={{color: 'gray'}}> Education </h6>
					  </Link>
					  <div style={{
						  width: '5px',
						  height: '5px',
						  marginLeft: '8px',
						  marginRight: '8px',
						  backgroundColor: mode === 'light' ? 'black' : 'white'
					  }}/>
					  <Link style={{color: 'black'}} to={`/education/${educator.educator._id}`}>
						  <h6 style={{color: 'gray'}}> {educator ? educator.educator.name : ''} </h6>
					  </Link>
					  <div style={{
						  width: '5px',
						  height: '5px',
						  marginLeft: '8px',
						  marginRight: '8px',
						  backgroundColor: mode === 'light' ? 'black' : 'white'
					  }}/>
					  <Link style={{color: 'black'}} to={`/education/${educator.educator._id}/${params.courseId}/modules`}>
						  <h6 style={{color: 'gray'}}> {module ? module.title : ''} </h6>
					  </Link>
					  <div style={{
						  width: '5px',
						  height: '5px',
						  marginLeft: '8px',
						  marginRight: '8px',
						  backgroundColor: mode === 'light' ? 'black' : 'white'
					  }}/>
					  <h6 style={{color: mode === 'light' ? 'black' : 'white'}}> {lesson ? lesson.title : ''} </h6>
				  </div>
				  <div className="d-flex align-items-start">
					  <div className="left-content-box p-4 overflow-y-auto">
						  {/* media bar */}
						  {/* Player Box */}
						  <div className="player_box">
							  <div className="row ">
								  <div className="col-12" style={{ height: 'auto'}}>
									  {isPlay ? (
										  <Player currentVideoURL={lesson ? lesson.url : ''} playStarted={isPlay}/>
									  ) : (
										  <div className=" position-relative player-prev">
											  <img src={module ? module.thumbnail : '"/img/course/thumb.png"'}
												   alt="thumb"/>
											  <div className="position-absolute overly">
												  <button
													  type="button"
													  className="btn"
													  onClick={() => setPlay(true)}
												  >
													  <FaPlay/>
												  </button>
											  </div>
										  </div>
									  )}
								  </div>
							  </div>
						  </div>
						  <div style={{marginTop: '36px', marginBottom: '20px'}} className="content">
							  <h3 style={{color: mode === 'light' ? 'black' : 'white'}}>{lesson ? lesson.title ?? '' : ''}</h3>
						  </div>
						  <div className="avatar-area d-flex align-items-center justify-content-between pb-5">
							  <div className="d-flex align-items-center">
								  <div className="avatar">
									  <img style={{borderRadius: '100px'}}
										   src={educator ? (educator.educator.photo ?? '' !== '' ? educator.educator.photo : "/img/blankUser.png") : "/img/blankUser.png"}
										   alt="avatar"/>
									  <div className="verified-status">
										  <FaCheck/>
									  </div>
								  </div>
								  <div className="content">
									  <h1 style={{color: mode === 'light' ? 'black' : 'white'}}>
										  {educator ? (educator.educator.name ?? '' !== '' ? educator.educator.name : '') : ''}
										  <span></span>
									  </h1>
									  {/*<p>{educatorSubscribers ?? 0} Subscribers</p>*/}
								  </div>
							  </div>
							  <div className="action-btn">
								  {/*<button type="button" style={{height: '50px'}} className="btn active mx-4"><FaShareAlt*/}
									{/*  className="mx-2"/> Share*/}
								  {/*</button>*/}
								  <button onClick={() => handleCompleted()} type="button" style={{height: '50px'}} className={`btn ${progress ? 'active' : ''} `}>
									  {/*<FaRegHeart className="mx-2"/>*/}
									  {progress ? 'Video Completed' : 'Mark Completed'}
								  </button>
							  </div>
						  </div>
						  {/* <div className="video-details">
                <div className="row">
                    <div className="col-8">
                        <div className="content">
                            <h1>{lesson ? lesson.title ?? '' : ''}</h1>

                            <p>{lesson ? lesson.description ?? '' : ''}</p>

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="right-content">
                            <div className="views d-flex align-items-center pb-3">
                                <FaEye className="mx-2"/>
                                <p>125,908 views</p>
                            </div>
                             <div className="reaction d-flex align-items-center pb-3">
                                <FaRegHeart className="mx-2" />
                                <p>{lessonLikes} likes</p>
                            </div>
                        <div className="live d-flex align-items-center pb-3">
                                <FaChartLine className="mx-2"/>
                                <p>1,938,394 streaming</p>
                            </div>
                            <div className="time d-flex align-items-center pb-3">
                                <IoIosNotifications className="mx-2"/>
                                <p>Uploaded {getTimeAgoSinceTimestamp(0)}</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div> */}

						  <div className="video-description p-4">
							  <p>{lesson.description !== '' ? lesson.description : module ? module.description : ''}</p>
						  </div>
						  <LiveChat mode={mode}/>
					  </div>
					  <div className="right-content-box p-4">
						  <div className="right-sidebar  p-4" style={{ height: 'calc(100vh - 200px)'}}>
							  <div className="top-bar d-flex justify-content-between align-items-center"
								   style={{marginBottom: '15px', marginTop: '0px'}}>
								  <h1>Next Videos</h1>
								  <button type="button" className="btn">See all</button>
							  </div>
							  {
								  upcomingVideos && upcomingVideos.map((items, id) => {
									  return (
										  <div style={{ cursor: 'pointer'}} className="video-items mb-3" key={id}
											   onClick={() => handleUpcomingSelected(items._id)}>
											  <div className="d-flex">
												  <div className="position-relative  d-inline-block">
													  <img src={module ? module.thumbnail : ''}
														   style={{objectFit: 'cover', borderRadius: '12px'}}
														   alt="avatar2"/>
													  <div className="overly">
														  <button type="button" className="btn">
															  <FaPlay/>
														  </button>
													  </div>
												  </div>
												  <div className="content">
													  <h1>{items.title}</h1>
													  <h4 className="sub-name">{items.subName}</h4>
													  <p>{module ? module.title : ''}</p>
												  </div>
											  </div>
										  </div>
									  )
								  })
							  }

						  </div>
					  </div>
				  </div>
				  <div className="effect1">
					  <img src="/img/course/effect1.png" alt="effect1"/>
				  </div>
				  <div className="effect2">
					  <img src="/img/course/effect2.png" alt="effect1"/>
				  </div>
			  </Wrapper>
		  )}
	  </DashboardLayout>
	</div>
	);
};
const Wrapper = styled.div`
	.effect1 {
		position: absolute;
		top: -43px;
		z-index: -1;
		right: 20px;

		img {
			height: 700px;
			width: 700px;
		}

		filter: blur(10px);
	}

	.effect2 {
		position: absolute;
		top: 120px;
		z-index: -1;
		left: 0;
		filter: blur(20px);

		img {
			height: 700px;
			width: 700px;
		}
	}

	p {
		margin: 0;
		padding: 0;
	}

	.player-prev {
		height: 567px;
		border-radius: 20px;

		img {
			width: 100%;
			object-fit: cover;
			height: 100%;
			border-radius: 20px;
		}

		.overly {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		button {
			border: 1px solid rgba(255, 255, 255, 1);
			background: transparent;
			height: 80px;
			width: 80px;
			border-radius: 50px;
			padding: 0;

			svg {
				color: #fff;
				font-size: 35px;
			}
		}
	}

	.left-content-box {
		width: 100%;
		height: 89vh;

		&::-webkit-scrollbar {
      display: none;
    }
  }
  .right-content-box {
    width: 460px;
    position: relative;
    height: 89vh;
  }
  .player_box {
    height: 567px;
    border-radius: 40px;
    width: 100%;
    video{
        height: 567px !important;
        width: 100%;
        object-fit: cover;
    }
    img{
        height: 100%;
    }
  }
  .right-sidebar {
    width: 432px;
    border-radius: 40px;
    top: 120px;
    background: ${(props) => props.mode === 'light' ?  '#FFFFFF' : 'linear-gradient(190.56deg, rgba(55, 43, 4, 0.4) -18.52%, rgba(255, 170, 0, 0.4) 52.54%)'};

    .top-bar {
        h1{
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }
        button{
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
    .video-items{
        img{
            width: 142px;
            height: 106px;
        }
        .overly{
            position: absolute;
            top: 0;
            bottom:0;
            right: 0;
            left: 0;
            height: 100%;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                background:rgba(255, 193, 0, 0.2);
                width: 36.46px;
                height: 36.46px;
                border: 1px solid rgba(255, 255, 255, 1); 
                border-radius: 20px;
                svg{
                    color:rgba(255, 255, 255, 1);
                }
            }
        }
        .content{
            padding-left:20px;
            padding-top:10px;
            h1{
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.0em;
                text-align: left;
            }
            h4{
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color:rgba(173, 137, 25, 1);
            }
            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color:rgba(173, 137, 25, 1);
            }
        }
    }
    .profile {
      background: rgba(101, 70, 5, 1);
      border-radius: 100px;
      padding: 5px;
      position: relative;
      margin-top: -75px;
    }
    .profile-info {
      .name {
        padding: 5px 0;
        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 29px;
          letter-spacing: 0.04em;
          text-align: left;
        }
        img {
          height: 20px;
          width: 20px;
          margin-left: 5px;
        }
      }
      .role {
        h3 {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.04em;
          text-align: left;
          color: rgba(255, 193, 0, 1);
        }
      }
    }
  }

.video-description{
  background-color: ${(props) => props.mode === 'light' ?  '#FFFFFF' : 'rgba(128, 88, 4, 0.7)'};
  border-radius: 15px;
  p{
    word-break:break-all;
	  color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
  }
}

  .divider {
    background-color: rgba(169, 115, 5, 1);
    height: 2px;
    width: 100%;
  }
  .avatar-area{
    .avatar{
        position: relative;
        img{
            height: 82px;
            width: 82px;
        }
        .verified-status{
            position: absolute;
            bottom: 3px;
            width: 33px;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:20px;
            background:rgba(255, 193, 0, 1);
            right: -2px;
        }

    }
    .content{
        padding-left:15px;
            h1{
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0.04em;
                text-align: left;
                color:   ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
                padding-bottom:10px;
                display: flex;
                align-items: center;
                span{
                    box-shadow: 0px 4px 4px 0px rgba(164, 255, 24, 0.4);
                    background-color:rgba(122, 202, 0, 1);
                    width: 13px;
                    height: 13px;
                    display: block;
                    border-radius:25px;
                    margin-left:7px;
                }
            }
            p{
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(255, 193, 0, 1);
            }
        }
    .action-btn{
        .active{
			border-radius: 8px;
            background: ${(props) => props.mode === 'light' ? '#FFC100' : 'linear-gradient(173.43deg, rgba(255, 193, 0, 0.4) -10.49%, rgba(255, 170, 0, 0.4) 74.76%)'};
            border: rgba(255, 193, 0, 0.4);
            color: #fff;
        }
        button{
            border: 1px solid rgba(255, 193, 0, 1);
            color: rgba(255, 193, 0, 1);
        }
    }    
  }
  .video-details{
    .content{
        h1{
            font-size: 40px;
            font-weight: 500;
            line-height: 43px;
            letter-spacing: 0em;
            text-align: left;
            color:  rgba(255, 255, 255, 1);
            padding-bottom:20px;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(255, 255, 255, 1);
        }
    }
    .right-content{
        svg{
            color: rgba(152, 129, 56, 1);
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(152, 129, 56, 1);
        }
    }
  }
  .live-chat{
    .top-bar{
        h1{
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-align: left;
            color: rgba(255, 193, 0, 1);
        }
        span{
                display: block;
                height: 10px;
                width: 10px;
                border-radius: 20px;
                background-color: rgba(121, 255, 60, 1);
                position: relative;
                top: -10px;
            }
      .divider{
        height: 23px;
        width: 2px;
        background-color: rgba(160, 160, 160, 1);
        margin: 0px 9px;
      }   
      h2{
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0.04em;
        text-align: left;
        color: ${(props) => props.mode === 'light' ? '#FFC100' : 'rgba(255, 255, 255, 1)'};
      }   
    }
  }
  .chat-area{
        .left-chat{
            .avatar{
                img{
                    width: 37px;
                    height: 37px;
                    border-radius:25px;
                }
            }
        .title{
            h2{
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
            }
            span{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color:rgba(255, 193, 0, 1);
            }
        }
        .details{
            p{
                padding-left:5px;
                padding-top:8px;
                font-size: 16px;
                font-weight: 300;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
				color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
            }
        }
        .arrow-icon{
            svg{
                color: rgba(255, 193, 0, 1);
            }
        }
        }
        .right-chat{
            margin-left:auto;
            .avatar{
                img{
                    width: 37px;
                    height: 37px;
                    border-radius:25px;
                }
            }
        .title{
            h2{
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ?  '#000000' : '#FFFFFF'};
            }
            span{
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;
                color:rgba(255, 193, 0, 1);
            }
        }
        .details{
            p{
                padding-left:5px;
                padding-top:8px;
                font-size: 16px;
                font-weight: 300;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-align: left;

            }
        }
        .arrow-icon{
            svg{
                color: rgba(255, 193, 0, 1);
            }
        }
        }
        .form-group{
            input{
                height: 56px;
                border: 1px solid rgba(254, 196, 79, 1);
                background-color:transparent;
                border-radius: 15px;
                padding-left:60px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ?  '#FFC100' : 'rgba(173, 137, 25, 1)'};
                &::placeholder{
                    font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: ${(props) => props.mode === 'light' ?  '#000000' : 'rgba(173, 137, 25, 1)'};
                }
            }
            .dot-menu{
                position: absolute;
                left: 10px;
                height: 100%;
                top:0;
                display:flex;
                align-items: center;
                justify-content: center;
                button{
                    border-radius:25px;
                    background-color:${(props) => props.mode === 'light' ?  '#FFC100' : 'rgba(173, 137, 25, 1)'};
                    height: 30px;
                    width: 30px;
                    padding: 0;
                    margin-left:10px;
					color: #0d0b06;
                svg{
                    color: rgba(254, 196, 79, 1);
                }
                }
            }
            .send-btn2{
                position: absolute;
                right: 10px;
                height: 100%;
                top:0;
                display:flex;
                align-items: center;
                justify-content: center;
                button{
                    border-radius:25px;
                    background-color: ${(props) => props.mode === 'light' ?  '#FFC100' : 'rgba(173, 137, 25, 1)'};
                    height: 30px;
                    width: 30px;
                    padding: 0;
                    margin-left:10px;
                svg{
                    color: #fff;
                }
            }
        }
        }
  }
`;
export default CourseDetails;
