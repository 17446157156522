import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useScroll from "../hooks/useScroll";
import {
	_ChatIcon,
	AffiliateIcon,
	ArrowRight,
	ChatIcon,
	CogIcon,
	DarkModeIcon,
	DashboardIcon,
	EducationIcon,
	LightModeIcon,
	NewsIcon,
	SearchIcon,
	Star,
	TeacherIcon, TelegramIcon
} from "./Icon";
import styled from "styled-components";
import { Button, Image } from "@chakra-ui/react";
import UserService from "../hooks/UserProvider";
import UserProvider from "../hooks/UserProvider";
import useRouter from "../hooks/useRouter";


const tenant = 0;

const DashboardLayout = ({ title, children, mode, toggleMode }) => {

	const [userData, setUserData] = useState();
	const [navigation, setNavigation] = useState();
	const [count, setCount] = useState(0); // State for triggering re-render

	const router = useRouter();

	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
	const handleSubmit = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		setCount(prevCount => prevCount + 1);
	},[])

	const scroll = useScroll();

	const logoutUser = (e) => {
		e.preventDefault();
		localStorage.removeItem('user');
		window.location.reload();
	};

	const fetchUser = async () => {
		const currentUser = await UserProvider.getCurrentUser(false);
		if (currentUser) {
			// console.log(currentUser)
			setupNavigation(currentUser);
		}
	};

	const setupNavigation = (fetchedUserData) => {
		if (fetchedUserData) {
			const shouldDisable = fetchedUserData.limited_user === true;
			let sidebarData;
			if (shouldDisable) {
				sidebarData = [
					{
						name: "Dashboard",
						icon: <DashboardIcon />,
						link: "/dashboard",
						disabled: shouldDisable
					},
					{
						name: "Education",
						icon: <EducationIcon />,
						link: "/education",
						disabled: false
					},
					{
						name: "Join Telegram",
						icon: <TelegramIcon />,
						link: "https://t.me/+HGLluWYxCZAxMTc0",
						disabled: false
					},
					{
						name: "Newsfeed",
						icon: <NewsIcon />,
						link: "/news",
						disabled: shouldDisable
					},
					{
						name: "Affiliate",
						icon: <AffiliateIcon />,
						link: "/affiliate",
						disabled: shouldDisable
					},
					// {
					// 	name: "Teacher",
					// 	icon: <TeacherIcon />,
					// 	link: "/educators",
					// 	disabled: shouldDisable
					// },
					{
						name: "Messenger",
						icon: <_ChatIcon />,
						link: "/chatrooms",
						disabled: shouldDisable
					},
					{
						name: "My Account",
						icon: <CogIcon />,
						link: "/myaccount",
						disabled: false
					},
				]
			} else {
				sidebarData = [
					{
						name: "Dashboard",
						icon: <DashboardIcon />,
						link: "/dashboard",
						disabled: shouldDisable
					},
					{
						name: "Education",
						icon: <EducationIcon />,
						link: "/education",
						disabled: false
					},
					{
						name: "Telegram",
						icon: <TelegramIcon mode={mode} />,
						link: "https://t.me/+HGLluWYxCZAxMTc0",
						disabled: false
					},
					{
						name: "Newsfeed",
						icon: <NewsIcon />,
						link: "/news",
						disabled: shouldDisable
					},
					{
						name: "Affiliate",
						icon: <AffiliateIcon />,
						link: "/affiliate",
						disabled: shouldDisable
					},
					// {
					// 	name: "Teacher",
					// 	icon: <TeacherIcon />,
					// 	link: "/educators",
					// 	disabled: shouldDisable
					// },
					{
						name: "Messenger",
						icon: <_ChatIcon />,
						link: "/chatrooms",
						disabled: shouldDisable
					},
					{
						name: "My Account",
						icon: <CogIcon />,
						link: "/myaccount",
						disabled: false
					},
				]
			}
			const data = sidebarData;

			setUserData(fetchedUserData);
			setNavigation(data);
		}

	}

	function handleNavigationClick(e, navItem) {
		if (navItem.disabled) {
			e.preventDefault()
		}
	}
	function getHeaderLogo(mode) {
		if (tenant === 0) {
			if (mode !== 'light') {
				return "/img/header_logo.png";
			} else {
				return '/img/lightLogo.png';
			}
		} else {
			if (mode === 'light') {
				return "/img/header_logo_dark.png";
			} else {
				return '/img/header_logo_light.png';
			}
		}
	}

	useEffect(() => {
		fetchUser();
	},[]);

	return (
		<>
			<main className="dashboard-layout">
				{/*<div*/}
				{/*	className="dashboard-bg"*/}
				{/*	style={{*/}
				{/*		background: `url("/img/dashboard-bg.png") no-repeat center center / cover fixed`,*/}
				{/*	}}*/}
				{/*/>*/}
				{/*<div style={{*/}
				{/*	position: 'absolute',*/}
				{/*	display: 'flex',*/}
				{/*	width: '100%',*/}
				{/*	height: '100vh',*/}
				{/*	overflow: 'hidden',*/}
				{/*	alignItems: 'center',*/}
				{/*	justifyContent: 'center'*/}
				{/*}}>*/}
				{/*	<video style={{*/}
				{/*		position: 'absolute',*/}
				{/*		top: '50%',*/}
				{/*		left: '50%',*/}
				{/*		width: 'auto',*/}
				{/*		height: 'auto',*/}
				{/*		maxWidth: '1920px',*/}
				{/*		maxHeight: '1080px',*/}
				{/*		objectFit: 'contain',*/}
				{/*		transform: 'translate(-40%, -50%)'*/}
				{/*	}} autoPlay loop muted className="background-video">*/}
				{/*		<source src="/video/background_dark_1.mp4" type="video/mp4"/>*/}
				{/*		Your browser does not support the video tag.*/}
				{/*	</video>*/}
				{/*</div>*/}

				<aside
					className={`dashboard-sidebar ${isSidebarOpen ? "active" : ""}`}
				>
					<div
						className={`logo d-none d-lg-block ${
							isSidebarOpen ? "" : "px-3"
						}`}
					>
						<Link to="/">
							<img style={{marginTop: '20px', objectFit: 'contain'}} height={tenant === 1 ? 60 : 47}
								 src={isSidebarOpen ? getHeaderLogo(mode) : "/img/fav.png"}
								 alt="Logo"
							/>
						</Link>
					</div>
					<div className="logo d-lg-none">
						<Link to="/">
							<img src="/img/logo.png" alt=""/>
						</Link>
					</div>
					<hr
						className="m-0 mx-2"
						style={{borderColor: "#3E3007", opacity: "1"}}
					/>
					<div className="sidebar-menu">
						{navigation && navigation.map((item, index) => (
							<NavLink style={item.disabled ? ({opacity: 0.4}) : {opacity: 1}}
									 onClick={(e) => handleNavigationClick(e, item)}
									 to={item.link}
									 className={`menu-item ${
										 title === item.name ? "active" : ""
									 }`}
									 key={index}
							>
								<span className="icon">{item.icon}</span>
								<span className="name">{item.name}</span>
							</NavLink>
						))}
					</div>
					<div className="mt-auto">
						<div className="mt-4 aside-bottom">
							{/*<div*/}
							{/*	className="inner"*/}
							{/*	style={{*/}
							{/*		background: `url('/img/sidebar-bottom-bg.png') no-repeat center center / cover`,*/}
							{/*	}}*/}
							{/*>*/}
							{/*	<h6 className="subtitle">*/}
							{/*		Full Access Coming Soon!*/}
							{/*	</h6>*/}
							{/*	<button type="button" className="pro-button">*/}
							{/*		Get Notified!*/}
							{/*	</button>*/}
							{/*</div>*/}
						</div>
					</div>
				</aside>
				<article style={{marginTop: '20px'}} className="dashboard-content">
					<header
						className={`header-section ${scroll > 0 ? "active" : ""}`}
					>
						<h4 className="title d-none d-md-block">{title}</h4>
						<div className="search-form">
							<form onSubmit={handleSubmit}>
								<div className="position-relative">
									<input
										type="text"
										className="form-control"
										placeholder="Search"
									/>
									<button
										type="submit"
										className="no-gutter submit--btn"
									>
										<SearchIcon/>
									</button>
								</div>
							</form>
						</div>
						<div className="header-right">
							<div className="dark-light-mode d-flex align-items-center">
								<Button className={mode === 'light' ? 'active btn' : 'btn'}
										onClick={toggleMode}>
									<LightModeIcon/>
								</Button>
								<Button className={mode === 'dark' ? 'active btn' : 'btn'} onClick={toggleMode}>
									<DarkModeIcon className="darkModeIcon"/>
								</Button>
							</div>
							<button className="premium-btn" type="button">
								<Star/> <span>Premium</span>
							</button>
							<Dropdown className="user-dropdown">
								<Dropdown.Toggle variant="success">
									<img
										src={userData && (userData.photo ?? '') !== '' ? userData.photo : "/img/blankUser.png"}
										alt=""
										className="user-img"
										width={40}
									/>
									<span className="user-name  d-none d-lg-block">
										{userData ? userData.name : ''}
									</span>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{/* <Dropdown.Item href="#/action-1">
										Change Profile Photo
									</Dropdown.Item> */}
									<Dropdown.Item href="#/action-2">
										Profile Settings
									</Dropdown.Item>
									<Dropdown.Item onClick={(e) => logoutUser(e)}>
										Logout
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</header>
					<div className="d-md-none">
						<h5 className="title">{title}</h5>
					</div>
					<Wrapper className="overflow-y-auto page_content">
						{children}
					</Wrapper>

					{/* <button
						className={`no-gutter toggle-button ${
							isSidebarOpen ? "active" : ""
						}`}
						type="button"
						onClick={() => setIsSidebarOpen(!isSidebarOpen)}
					>
						<ArrowRight />
					</button> */}
				</article>
			</main>
		</>
	);
};

const Wrapper = styled.div`
	height: 91vh;
	max-width: 1600px;
	margin: 0 auto;
	display: block;

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;

	&::-moz-scrollbar {
		display: none !important;
	}

`

export default DashboardLayout;
