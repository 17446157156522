import { faker } from "@faker-js/faker";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";

import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			type: "category",
			grid: {
				drawBorder: false,
				borderColor: "#ffffff00",
				color: "#ffffff2a",
			},
			ticks: {
				maxRotation: 0,
				minRotation: 0,
				color: "#ffffff",
			},
		},
		y: {
			display: true,
			grid: {
				color: "#ffffff2a",
				drawBorder: false,
				lineWidth: 0,
			},
			ticks: {
				color: "#fcd100",
				fontWidth: 700,
				callback: function (value, index, values) {
					return value + " Hr";
				},
			},
		},
	},
};

const labels = [
	"10 Oct",
	"12 Oct",
	"14 Oct",
	"16 Oct",
	"18 Oct",
	"20 Oct",
	"22 Oct",
	"24 Oct",
];

export const data = {
	labels,
	datasets: [
		// {
		// 	data: labels.map(() => faker.datatype.number({ min: 0, max: 60 })),
		// 	borderColor: "#FFC10050",
		// 	backgroundColor: "#FFC10050",
		// 	lineTension: 0.4,
		// 	radius: 3,
		// },
		// {
		// 	data: labels.map(() => faker.datatype.number({ min: 0, max: 60 })),
		// 	borderColor: "#ffffff",
		// 	backgroundColor: "#ffffff",
		// 	lineTension: 0.4,
		// 	radius: 3,
		// },
	],
};

const AffiliateChart = () => {
	return <Line options={options} data={data} />;
};
export default AffiliateChart;
