import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { storageRef } from '../hooks/firebase';
import { v4 } from 'uuid';

const FirebaseMultimediaUploader = {

    async uploadFile(fileList) {
        if (fileList.length > 0) {
            const selectedFile = fileList[0];
            const uid = v4();
            try {
                const snapshot = await storageRef.ref(`api_multimedia/${uid}_${selectedFile.name}`).put(selectedFile);
                const downloadURL = await snapshot.ref.getDownloadURL();
                return downloadURL;
            } catch(downloadURLError) {
                console.log(downloadURLError);
            }
        }
    }
};

export default FirebaseMultimediaUploader;


